export const UPDATE_STORAGE_USAGR = 'platform/data/storage';
export const ALL_ASSETS = 'platform/all/assets';
export const APPS_SWITCHER = 'platform/app/switcher';
export const PORTFOLIO_LIST = 'platform/portfolios';
export const OPERATOR_LIST = 'platform/operators';
export const REGION_LIST = 'platform/regions';
export const LESSEE_LIST = 'platform/lessees';
export const PLATFORM_CONTANTS = 'platform/constants/list';
export const AIRCRAFT_TYPES = 'platform/aircraft_types';
export const AIRCRAFT_MNT_GRP = 'platform/aircraft/mnt/grp';
export const ENGINE_TYPES = 'platform/engine_types';
export const APU_TYPES = 'platform/apu_types';
export const RECORDS_REPOS = 'platform/records/repos';
export const RECORDS_SEARCH_RESULT = 'platform/records/seach';
export const RECORDS_RENAMED = 'platform/records/seach/renamed';
export const RECORDS_DELETE = 'platform/records/seach/delete';
export const RECORDS_SEARCH_FILTER = 'platform/records/seach/filter';
export const SEARCH_LOADER = 'platform/records/loader';
export const UNMOUNT_ASSET_LIST = 'platform/records/unmountAssetList';
export const FILTERED_AIRCRAFT_TYPE_LIST = 'platform/filtered_aircraft_type_list';
export const FILTERED_ENGINE_TYPE_LIST = 'platform/filtered_engine_type_list';
export const FILTERED_APU_TYPE_LIST = 'platform/filtered_apu_type_list';
