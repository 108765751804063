import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { Drawer, Grid, Container, Avatar, Paper, TextField, Tooltip, IconButton, Link } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import SettingsPowerIcon from '@material-ui/icons/SettingsPower';
import MainNavLink from './MainNavLink';
import PageLoader from './PageLoader';
import WhatsNewCompleteList from './WhatsNewCompleteList';
import FAQsList from './FAQsList';
import ReportAnIssue from './ReportAnIssue';
import { lessorUpdateApi } from '../../application/auth/apiServices';
import { globalRecordsSearchAc, globalFilterRecordsSearchAc } from '../actionCreators';
import * as actions from '../actions';
import { getLocalStorageInfo } from '../../utils';
import config from '../../config';
const queryString = require('query-string');
class MobileHeader extends Component {
  constructor(props){
    super(props);
    this.state = {
      search:queryString.parse(this.props.location.search).q,
      left:false,
      formSubmitLoader:false,
    }
    this.lessorUpdateApi = lessorUpdateApi.bind(this)
  }
  onSearchData = () => {
    const { search } = this.state;
    if(search && search.trim().length){
      this.props.globalRecordsSearchFn({q:search, page:1, per_page:20});
      this.props.globalRecordsSearchFilter({q:search})
    }else{
      this.props.enqueueSnackbar("Please enter the keyword for search", {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  }
  render() {
    const { left, search, formSubmitLoader } = this.state;
    return (
      <div>
        {formSubmitLoader ? <PageLoader /> : null}
        <header className="mobile-header">
          <Container maxWidth={false}  style={{padding:'0px 15px'}}>
            <Grid container  spacing={0} alignItems="center" justify="center">
              <Grid item xs={12}>
                <div style={{display:'flex'}}> 
                  <ul className='list-inline flex-centered logo-search-blk'>
                    <li className="list-inline-item">
                      <MenuIcon
                        color="primary"
                        onClick={() =>  this.setState({left:true})}
                      />
                    </li>
                    <li className="list-inline-item">
                      <Link onClick={() => this.props.history.push('/assets')}>
                          <img src="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/sparta_logo.png" alt="Sparta" style={{width:'35px', paddingLeft:'10px'}} />
                      </Link>
                    </li>
                  </ul>
                  <form onSubmit={(e) => {e.preventDefault(); this.onSearchData() }}>
                      <input type="text" value={search} onChange={(e) => this.setState({search:e.target.value})} placeholder="Search for Files, Folders and Keyword" />
                  </form>
                </div>
              </Grid>
            </Grid>
          </Container>
        </header>
        <Drawer open={left} onClose={() =>  this.setState({left: false})}>
          <div className="mobile-main-navs" style={{width: '280px'}}>
            <Paper className="user-info">
              <div className="user-card">
                <Avatar className="user-avatar" alt={getLocalStorageInfo().user.name} src={getLocalStorageInfo().user.profile_pic} />
                <div>
                  <h3>
                    {getLocalStorageInfo().user.name}
                    <span onClick={() => {localStorage.clear(); this.props.history.push('/login')}} style={{float:'right'}}><SettingsPowerIcon color="primary" /></span>
                  </h3>
                  <span>{getLocalStorageInfo().user.designation?getLocalStorageInfo().user.designation:''}</span>
                </div>
              </div>
              <Autocomplete
                options = {getLocalStorageInfo().lessor_list}
                clearOnBlur={false}
                getOptionLabel={option => option.name}
                disableClearable={true}
                filterSelectedOptions={true}
                value={{id:getLocalStorageInfo().defaultLessor.id, name: getLocalStorageInfo().defaultLessor.name}}
                onChange={(e, data) => {data ? this.lessorUpdateApi(this.props, {lessor_id:data.id}) :e.preventDefault()}}
                renderInput={params => <TextField {...params} label="Accessing Lessor" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
              />
            </Paper>
            <Paper className="mobile-py-link" elevation={0}>
              <MainNavLink type="mobile" />
            </Paper>
          </div>
        </Drawer>
        <div style={{height:'50px'}}></div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  fleetFiles: state.homeReducer.fleetFiles,
  fleetActivityCount: state.homeReducer.fleetActivityCount,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    globalRecordsSearchFn: (query) => dispatch(globalRecordsSearchAc(ownProps, query)),
    globalRecordsSearchFilter: (query) => dispatch(globalFilterRecordsSearchAc(ownProps, query)),
    toggleTheAppSwitcher: (flag) => dispatch({type:actions.APPS_SWITCHER, payload:flag}),
  }
}
export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(MobileHeader)))
