import React from 'react';
import { trackActivity } from '../../../utils/mixpanel';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import {DropzoneArea} from 'material-ui-dropzone';
const UploadTemplate = ({toggleModalFn, uploadTemplateFile, templateUpdate}) => {
  return(
    <Dialog
      open={templateUpdate.modal}
      onClose={() => toggleModalFn(false)}
      aria-labelledby="scroll-dialog-title"
      fullWidth= 'true'
    >
      <DialogTitle id="scroll-dialog-title">
        Upload Template
      </DialogTitle>
      <DialogContent dividers={true} className="data-upload-modal">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="dropzone-area">
              <DropzoneArea
                filesLimit={1}
                acceptedFiles={['.xlsx','.xls']}
                maxFileSize={1000000}
                showPreviewsInDropzone={false}
                showPreviews={false}
                dropzoneText={<p>Drag & Drop files<br/> OR <br/> Click Here</p>}
                dropzoneClass="drag-drop-cnt"
                maxWidth="sm"
                showAlerts={['error', 'info']}
                alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
                onDrop={(files) => {uploadTemplateFile(files); trackActivity('Item Clicked',{page_title:'Settings', item_type:'Drag and Drop Area used', event_desc:'Used drag and Drop area to Upload file(s)'})}}
              />
              <p className="file-upload-note">Supported only Excel file</p>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => {toggleModalFn(false); trackActivity('Item Clicked', { page_title: 'Settings', event_type: 'Cancel Button', event_desc: ' Clicked on Cancel Button in form of Upload Template from Records Template in Settings Page '})}}>Cancel</Button>
      </DialogActions>
    </Dialog>

  )
}
export default UploadTemplate;
