import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Button, TableRow, TableCell, Tooltip, Checkbox, Avatar } from '@material-ui/core';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import DeleteIcon from '@material-ui/icons/Delete';
import PublishIcon from '@material-ui/icons/Publish';
import MenuStatus from './MenuStatus';
import { getFileSize, getFileIcon, getLocalStorageInfo, checkPermission } from '../../../utils';
import { displayDateFormatShort, imgPath, assetTypeValues } from '../../../constants';
import folderIcon from '../../../assets/images/folder_icon.svg'
import ocrCompletedIcon from '../../../assets/images/ocr_completed.png'
import ocrInqueueIcon from '../../../assets/images/ocr_inqueue.png'
import ocrProcessingIcon from '../../../assets/images/rolling.gif'
import ocrRejectedIcon from '../../../assets/images/ocr_rejected.png'
import { permissionKeys } from '../'
const ActionDockFile = ({ item, history, match, bulkOperation, toggleBulkOps, docksSingleActionFn, getFileRename, fileIcons }) => {
  return (
    <TableRow hover tabIndex={-1}>
      {checkPermission('reviewdock',permissionKeys[match.params.type],'PUB_REJ') || checkPermission('reviewdock',permissionKeys[match.params.type],'D') || checkPermission('reviewdock',permissionKeys[match.params.type],'RSTOR') ?
        <TableCell width="20">
          <Checkbox
            name={item.id}
            color="primary"
            className="checkbox-icon"
            size="small"
            onChange={(e) => toggleBulkOps(e.target.checked)}
            checked={bulkOperation.ids.includes(item.uuid)}
          />
        </TableCell> : null
      }

      <TableCell className="file-info" width="340" style={{ cursor: 'pointer' }} onClick={() => window.open(`/records/preview/${item.uuid}`, '_blank')}>
        <div className="flex-centered">
          <img className="folder-icon" width="20" src={getFileIcon(fileIcons, item.extension)} alt="Folder" />
          <div>
            <h5>
              {item.name}
            </h5>
            <p>
              {item.location}
            </p>
          </div>
        </div>
      </TableCell>

      <TableCell>
        <div className="asset-info">
          {item.asset && item.asset.type ? <p>{item.asset.type}</p> : null}
          <h5>{item.asset && item.asset.asset_type_number && assetTypeValues[item.asset.asset_type_number].label ? assetTypeValues[item.asset.asset_type_number].label : ''} {item.asset ? item.asset.serial_no : ''}</h5>
        </div>
      </TableCell>
      <TableCell>{getFileSize(item.size)}</TableCell>
      <TableCell >
        <div className="flex-centered">
          <Avatar variant="circular" style={{ float: 'left', marginRight: '6px' }} alt="" src={ item?.created_by?.name ? item?.created_by?.profile_pic||'' :''} />
          {item?.created_by?.name ? item.created_by.name:''}
        </div>
      </TableCell>
      <TableCell >
        <div className="flex-centered">
          <Avatar variant="circular" style={{ float: 'left', marginRight: '6px' }} alt="" src={ item?.modified_by?.name ? item?.modified_by?.profile_pic||'' :item?.created_by?.profile_pic||''} />
          {item?.modified_by?.name ? item.modified_by.name:item.created_by.name}
        </div>
      </TableCell>
      <TableCell>
        <div className="flex-centered">
          {item.modified ? moment(item.modified).format(displayDateFormatShort) : ''}
        </div>
      </TableCell>
      {match.params.type === 'in_queue' ?
        <Fragment  >
          <TableCell width='150px'>
            {checkPermission('reviewdock',permissionKeys[match.params.type],'PUB_REJ') ?
              <MenuStatus
                docksSingleActionFn={(title) => docksSingleActionFn(title)}
              /> :
              'Pending'
            }
          </TableCell>
          <TableCell className="actions-cell" >
            {item.extension.toLowerCase() === 'pdf' ?
              <Fragment>
                {item.ocr_status === "1" ? <Tooltip title="OCR Inqueue" arrow><img width="20" src={ocrInqueueIcon} alt="Inqueue" /></Tooltip> : null}
                {item.ocr_status === "2" ? <Tooltip title="OCR Processing" arrow><img width="20" src={ocrProcessingIcon} alt="Processing" /></Tooltip> : null}
                {item.ocr_status === "3" ? <Tooltip title="OCR Completed" arrow><img width="20" src={ocrCompletedIcon} alt="Completed" /></Tooltip> : null}
                {item.ocr_status === "4" ? <Tooltip title="OCR Rejected" arrow><img width="20" src={ocrRejectedIcon} alt="Rejected" /></Tooltip> : null}
              </Fragment> : null
            }
          </TableCell>
        </Fragment> : null
      }
      {match.params.type === 'rename_queue' ?
        <Fragment>
          <TableCell className="actions-cell" >
            <ul className="list-inline cta-mobile">
              <li className="list-inline-item" style={{ marginRight: '10px' }}>
                <Button
                  variant="contained"
                  disableElevation={true}
                  disabled={checkPermission('reviewdock',permissionKeys[match.params.type],'RENAME') ? false : true}
                  size="small"
                  onClick={getFileRename}
                  style={{ backgroundColor: '#FFD580' }}
                >
                  Rename
                </Button>
              </li>
              <li className="list-inline-item">
                { checkPermission('reviewdock',permissionKeys[match.params.type],'PUB_REJ') ?
                  <MenuStatus
                    docksSingleActionFn={(title) => docksSingleActionFn(title)}
                  /> : 'Pending'
                }
              </li>
            </ul>
          </TableCell>
        </Fragment> : null
      }
      {
        match.params.type === 'ocr_queue' ?
          <TableCell className="actions-cell" >
            {item.extension.toLowerCase() === 'pdf' ?
              <Fragment>
                <Tooltip title="OCR Inqueue" arrow><img width="20" src={ocrInqueueIcon} alt="Inqueue" /></Tooltip>
              </Fragment> : null
            }
          </TableCell>
          : null
      }
      { match.params.type === 'ocr_rejected_queue' || match.params.type === 'rejected_queue'|| match.params.type ==='corrupted_queue' ?
        <Fragment>
          <TableCell className="actions-cell" >
            {match.params.type === 'rejected_queue' && checkPermission('reviewdock',permissionKeys[match.params.type],'RSTOR') ?
              <Tooltip title="Restore" arrow>
                <SettingsBackupRestoreIcon onClick={() => docksSingleActionFn('Restore')} color="primary" />
              </Tooltip>
              : null
            }
            {checkPermission('reviewdock',permissionKeys[match.params.type],'D') ?
              <Tooltip title="Delete" arrow>
                <DeleteIcon onClick={() => docksSingleActionFn('Trash')} color="secondary" />
              </Tooltip>
              : null
            }
          </TableCell>
        </Fragment> : null
      }
    </TableRow>
  )
}
export default withRouter(ActionDockFile);
