import React, { useEffect, useState } from 'react';
import moment from "moment";
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {Accordion,AccordionSummary,AccordionDetails,Grid,Paper,TextField} from '@material-ui/core';
import Autocomplete from "@material-ui/lab/Autocomplete";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { fieldDateFormat, backendDateFormat, displayDateFormat } from "../../../constants";
import { regexConstants } from "../../../constants/regEx";
import { AutocompleteCreatable } from "../../../shared_elements";
import { checkApiStatus, checkPermission } from '../../../utils';
import { globalGetService, globalPutService, globalPostService } from '../../../utils/globalApiServices';
const OtherInformation = ({match, prsDetail}) => {
    const [isExpand, setExpand] = useState(true);
    const [prsInfo, setPrsInfo] = useState(prsDetail)
    const [categories, setCategories] = useState([]);
    const [users, setUsers] = useState([]);
    const [tags, setTags] = useState([]);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    useEffect(() => {
        getCategories();
        getAssignees();
        // getTags();
    },[]);
    
    const getCategories = () => {
        globalGetService(`records/asset/${match.params.assetType}/${match.params.assetSlug}/gap-report-sections/`)
        .then(response => {
            if(checkApiStatus(response)){
                setCategories(response.data.data.list)
            }
        })
    }
    const getAssignees = () => {
        globalGetService(`records/userslist/`,{application:6})
        .then(response => {
            if(checkApiStatus(response)){
                setUsers(response.data.data);
            }
        })
    }
    const getTags = () => {
        globalGetService(`technical/prs-lessor-tags/`)
        .then(response => {
            if(checkApiStatus(response)) {
                setTags(response.data.data);
            }
        })
    }
    const onUpdatePrsInfo = (key, value, required) => {
        if(key === 'tags'){
            globalPostService(`records/asset/${match.params.assetType}/${match.params.assetSlug}/issues/${match.params.slug}/`,{id:value.map(item=>item.id)})
            .then(response => {
                if(checkApiStatus(response)){
                    enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                }else{
                    enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
                }
            })
        }else{
            let payload = {[key]:value}
            globalPutService(`records/asset/${match.params.assetType}/${match.params.assetSlug}/issues/${match.params.slug}/`, payload)
            .then(response => {
                if(checkApiStatus(response)){
                    enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                }else{
                    enqueueSnackbar(response.data.message, { autoHideDuration:5000, variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
                }
            });
        }
    }
    return(
        <Paper>
            <Accordion expanded={isExpand} onChange={(e,expanded)=> setExpand(expanded)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
                    <h4>Other Information</h4>
                </AccordionSummary>
                <AccordionDetails>
                    <Paper style={{padding:'15px'}}>
                        <Grid container spacing={1}>
                            <Grid item md={4} xs={6}>
                                <AutocompleteCreatable
                                    disabled={!checkPermission('records','records_issue','U')}
                                    placeholder=""
                                    options={categories}
                                    value={prsInfo?.category || null }
                                    paramsKey='category'
                                    optionKey="name"
                                    required={false}
                                    multiple={false}
                                    label="Category"
                                    onFieldChange={(paramsKey, value) =>{setPrsInfo({...prsInfo,category:value}); onUpdatePrsInfo('category', value, false)}}
                                />
                            </Grid>
                            <Grid item md={4} xs={6}>
                                {(() => {
                                    let optionValue = prsInfo.priority ? prsInfo.priority:{ label: 'None', value: null }
                                    return(
                                        <Autocomplete
                                            disabled={!checkPermission('records','records_issue','U')}
                                            options={[{ label: 'None', value: null }, { label: 'P1', value: 1 }, { label: 'P2', value: 2 }]}
                                            getOptionLabel={(option) => option.label}
                                            id="priority"
                                            value={optionValue}
                                            onChange={(e, value) => { setPrsInfo({...prsInfo,priority:value}); onUpdatePrsInfo('priority', value?.value||null, false)}}
                                            renderInput={(params) => ( <TextField {...params} label="Priority" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' /> )}
                                        />
                                    )
                                })()}
                            </Grid>
                            <Grid item md={4} xs={6}>
                                <Autocomplete
                                    disabled={!checkPermission('records','records_issue','U')}
                                    options={users}
                                    getOptionLabel={(option) => option.name}
                                    id="assignee"
                                    value={prsInfo.assignee}
                                    onChange={(e, value) => { setPrsInfo({...prsInfo,assignee:value}); onUpdatePrsInfo('assignee', value?.id||null, false)}}
                                    renderInput={(params) => ( <TextField {...params} label="Assignee" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' /> )}
                                />
                            </Grid>
                            <Grid item md={4} xs={6}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        disabled={!checkPermission('records','records_issue','U')}
                                        margin="normal"
                                        id="started_date"
                                        label="Discrepancy Raised On"
                                        format={fieldDateFormat}
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ readOnly: true }}
                                        value={ prsInfo?.started_date || null}
                                        onChange={(data, value) => {setPrsInfo({...prsInfo,'started_date':data ? moment(data).format(backendDateFormat):null});onUpdatePrsInfo("started_date",data ? moment(data).format(backendDateFormat):null,false)}}
                                        inputVariant='outlined'
                                        clearable
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item md={4} xs={6}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        disabled={!checkPermission('records','records_issue','U')}
                                        margin="normal"
                                        id="estimated_completion_date"
                                        label="Discrepancy Resolved On"
                                        format={fieldDateFormat}
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ readOnly: true }}
                                        value={ prsInfo?.estimated_completion_date || null}
                                        onChange={(data, value) => {setPrsInfo({...prsInfo,'estimated_completion_date':data ? moment(data).format(backendDateFormat):null});onUpdatePrsInfo("estimated_completion_date",data ? moment(data).format(backendDateFormat):null,false)}}
                                        inputVariant='outlined'
                                        clearable
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item md={4} xs={6}>
                                <TextField
                                    disabled={!checkPermission('records','records_issue','U')}
                                    id="ata"
                                    label="ATA"
                                    fullWidth
                                    margin="normal"
                                    value={prsInfo?.ata || ""}
                                    onChange={(e) => setPrsInfo({...prsInfo,'ata':e.target.value})}
                                    onBlur={() => onUpdatePrsInfo('ata', prsInfo.ata, false)}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 25 }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={4} xs={6}>
                                <TextField
                                    disabled={!checkPermission('records','records_issue','U')}
                                    id="cycles_remaining"
                                    label="Cycles Remaining"
                                    fullWidth
                                    margin="normal"
                                    value={prsInfo?.cycles_remaining || ""}
                                    onChange={(e) => regexConstants.onlyNumeric.test(e.target.value) ? setPrsInfo({...prsInfo,'cycles_remaining':e.target.value}):e.preventDefault()}
                                    onBlur={() => onUpdatePrsInfo('cycles_remaining', prsInfo.cycles_remaining, false)}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 25 }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={4} xs={6}>
                                <TextField
                                    disabled={!checkPermission('records','records_issue','U')}
                                    id="part_number"
                                    label="Part Number"
                                    fullWidth
                                    margin="normal"
                                    value={ prsInfo?.part_number || ""}
                                    onChange={(e) => setPrsInfo({...prsInfo,'part_number':e.target.value})}
                                    onBlur={() => onUpdatePrsInfo('part_number', prsInfo.part_number, false)}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 25 }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={4} xs={6}>
                                <TextField
                                    disabled={!checkPermission('records','records_issue','U')}
                                    id="serial_number"
                                    label="Serial Number"
                                    fullWidth
                                    margin="normal"
                                    value={prsInfo?.serial_number || ""}
                                    onChange={(e) => setPrsInfo({...prsInfo,'serial_number':e.target.value})}
                                    onBlur={() => onUpdatePrsInfo('serial_number', prsInfo.serial_number, false)}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 25 }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={4} xs={6}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        disabled={!checkPermission('records','records_issue','U')}
                                        margin="normal"
                                        id="install_date"
                                        label="Installed On"
                                        format={fieldDateFormat}
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ readOnly: true }}
                                        value={ prsInfo?.install_date || null}
                                        onChange={(data, value) => {setPrsInfo({...prsInfo,'install_date':data ? moment(data).format(backendDateFormat):null});onUpdatePrsInfo("install_date",data ? moment(data).format(backendDateFormat):null,false)}}
                                        inputVariant='outlined'
                                        clearable
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item md={4} xs={6}>
                                <TextField
                                    disabled={!checkPermission('records','records_issue','U')}
                                    id="position"
                                    label="Position"
                                    fullWidth
                                    margin="normal"
                                    value={prsInfo?.position || ""}
                                    onChange={(e) => setPrsInfo({...prsInfo,'position':e.target.value})}
                                    onBlur={() => onUpdatePrsInfo('position', prsInfo.position, false)}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 25 }}
                                    variant='outlined'
                                />
                            </Grid>                     
                        </Grid>
                    </Paper>
                </AccordionDetails>
            </Accordion>
        </Paper>
    )
}
export default withRouter(OtherInformation);