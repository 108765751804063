import React, { Fragment, Component } from 'react';
import { isMobile } from 'react-device-detect';
import { Redirect, Link } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import {Dialog, DialogContent, DialogTitle, Grid, } from '@material-ui/core'
import { getLocalStorageInfo, getGlobalCookie, authorizeAutoLogin, getLessorsList, setGlobalCookie } from '../utils';
import { DesktopHeader, MobileHeader, DeskTopSidebar, SecondarySideNav } from '../shared_elements';
import { connect } from 'react-redux';
import config from '../config';
import * as actions from '../shared_elements/actions'
import restrictIcon from '../assets/images/restrict_icon.svg';
window.addEventListener('visibilitychange', checkLogin)
function checkLogin() {
  if (document.visibilityState || document.hasFocus) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let location = window.location.href
    let lessorAccess = JSON.parse(getGlobalCookie('lessorAccess'))
    if (!window.location.href.includes('login') && !window.location.href.includes('select-lessor')) {
      setGlobalCookie('redirectURIRecords', window.location.href, 3)
    }
    setTimeout(() => {
      if (lessorAccess === undefined || lessorAccess === null) {
        localStorage.clear();
        if (!location.includes('login') && !location.includes('shared-data') && !location.includes('records/preview')) {
          window.location.reload()
        }
      } else {
        if (lessorAccess.id && config.api.networkInterface.includes(lessorAccess.environment)) {
          if (!getLocalStorageInfo()) {
            authorizeAutoLogin({ lessor_id: lessorAccess.id })
          } else if (!location.includes('select-lessor') && !location.includes('otp')) {
            if (lessorAccess.id !== userInfo.defaultLessor.id || getLocalStorageInfo().access !== lessorAccess.access) {
              authorizeAutoLogin({ lessor_id: lessorAccess.id })
            } else {
              //window.location.assign('/')
            }
          }
        } else {
          if (userInfo && userInfo.defaultLessor == undefined) {
            // window.location.assign("/select-lessor");
          }
        }
      }
    }, 2000)
    // getLessorsList()
  }

}
export default function PageLayout(HocComponent, extraProps = {}) {
  class Layout extends Component {

    constructor(props) {
      super(props)
      this.state = {
        modal: false,
      }
    }
    componentDidMount() {
      checkLogin();
    }
    layoutMarginProps = () => {
      if (extraProps.layoutPhase) {
        switch (extraProps.layoutPhase) {
          case 0:
            // Desktop with Primary Header Only
            return '50px 0 0 0'
          case 1:
            // Desktop with Primary Header + Primary Sidenav
            return '0px 0 0 70px'
          case 2:
            // Desktop with Primary Header + Primary Sidenav + Secondary Sidenav
            return '0 0 0 240px';
          default:
            return '0'
        }
      } else {
        return '0'
      }
    }
    render() {
      const fileIcons = this.props.pltConstants.filter(icon => icon.type === 'file_extension_drive')
      if (getLocalStorageInfo() && getLocalStorageInfo().user) {
        if (getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.records) {
          return (
            <section>
              <Fragment>
                {isMobile ? <MobileHeader /> :
                  <Fragment>
                    <DesktopHeader />
                    <DeskTopSidebar />
                  </Fragment>
                }
              </Fragment>
              {isMobile ? null : extraProps.layoutPhase === 2 ? <SecondarySideNav /> : null}
              <main style={{ margin: isMobile ? '10px 0 0 0' : this.layoutMarginProps(), padding: isMobile ? '15px 15px 0px 15px' : '20px 20px 0px 20px' }}>
                <div>
                  <HocComponent {...this.props} />
                </div>
              </main>
              <Dialog
                open={this.state.modal}
                onClose={() => this.setState({ modal: false })}
                aria-labelledby="scroll-dialog-title"
                fullWidth='true'
              >
                <DialogTitle id="scroll-dialog-title">
                  Information<CloseIcon onClick={() => this.setState({ modal: false })} color="primary" style={{ float: 'right', cursor: 'pointer' }} />
                </DialogTitle>
                <DialogContent dividers={true} className="data-upload-modal">
                  <form>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        Dear User,
                        <br /><br />
                        SPARTA will be under routine maintenance and feature upgrade release from Saturday 12 Feb 2022 0030 Hrs UTC to Sunday 13 Feb 2022 2300 Hrs UTC. During this time, SPARTA will be accessible but may pose intermittent connection problems. We regret the inconvenience caused.
                        <br /><br />
                        If you have any concerns or questions, please contact us on <a href="mailto:support@sparta.aero">support@sparta.aero</a>.
                        <br /><br />
                        Regards,
                        <br />
                        The SPARTA Team
                      </Grid>
                    </Grid>
                  </form>
                </DialogContent>
              </Dialog>
            </section>
          );
        } else {
          return (
            <Fragment>
              {isMobile ? <MobileHeader /> :
                <Fragment>
                  <DesktopHeader />
                  <DeskTopSidebar />
                </Fragment>
              }
              <div className="page-not-found">
                <Grid container spacing={0}>
                  <Grid xs={12}>
                    <img src={restrictIcon} alt="Icon" />
                    <h2>You’re not permitted to see this.</h2>
                    <p>The page you’re trying to access has restricted access,<br />
                      if you feel this is a mistake contact to <Link href="mailto:support@sparta.aero" target="_blank">support@sparta.aero</Link> to access this application..
                    </p>
                    <Link className="start-over btn btn-default return-home" onClick={() => window.location.assign(`${config.domain.subDomian}`)}>Return Home</Link>
                  </Grid>
                </Grid>
              </div>
              <Dialog
                open={this.state.modal}
                onClose={() => this.setState({ modal: false })}
                aria-labelledby="scroll-dialog-title"
                fullWidth='true'
              >
                <DialogTitle id="scroll-dialog-title">
                  Information
                </DialogTitle>
                <DialogContent dividers={true} className="data-upload-modal">
                  <form>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        Dear User,
                        <br /><br />
                        SPARTA will be under routine maintenance and feature upgrade release from Saturday 12 Feb 2022 0030 Hrs UTC to Sunday 13 Feb 2022 2300 Hrs UTC. During this time, SPARTA will be accessible but may pose intermittent connection problems. We regret the inconvenience caused.
                        <br /><br />
                        If you have any concerns or questions, please contact us on <a href="mailto:support@sparta.aero">support@sparta.aero</a>.
                        <br /><br />
                        Regards,
                        <br />
                        The SPARTA Team
                      </Grid>
                    </Grid>
                  </form>
                </DialogContent>
              </Dialog>
            </Fragment>
          )
        }
      } else {
        return null
      }
    }
  }
  const mapStateToProps = (state) => ({
    pltConstants: state.shareReducer.pltConstants,
    applicationSwitcher: state.shareReducer.applicationSwitcher,
  })
  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      toggleTheAppSwitcher: (flag) => dispatch({ type: actions.APPS_SWITCHER, payload: flag }),
    }
  }
  return connect(mapStateToProps, mapDispatchToProps)(Layout)
}
