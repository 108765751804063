import React, { Component, Fragment } from 'react';
import { withSnackbar } from 'notistack';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { Button, Grid, Paper } from '@material-ui/core';
import { filePreviewDetailApi } from '../apiServices';
import { videoTypes, imageMedia, audioTypes } from '../../../constants';
import { Comments, LinkedIssues, UnlinkedIssues } from '../components';
import PageLoader from '../../../shared_elements/components/PageLoader'
import { checkApiStatus, checkPermission, getLocalStorageInfo } from '../../../utils';
import axios from 'axios'
import disabledFileIcon from '../../../assets/images/disabledFileIcon.svg';
import { trackFileDownloadActivity } from '../apiServices';
import { trackPublicActivity } from '../../../utils/mixpanel';
import { AddIssue } from '../../dataroom/components'
import { addNewIssueObj } from '../../dataroom'
import AdobePdfViewer from '../pdfAnnotation/AdobePdfViewer';
import { globalGetService } from '../../../utils/globalApiServices';
class FilePreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoader: false,
      annotationLoader:true,
      fileInfo: null,
      addEditIssue:{modal:false, mode:'', data:null},
      annotations:[],
      isFileAnnotationError:false
    };
    this.filePreviewDetailApi = filePreviewDetailApi.bind(this);
    this.trackFileDownloadActivity = trackFileDownloadActivity.bind(this);
  }
  componentDidMount() {
    this.filePreviewDetailApi(this.props, this.props.match.params.uuid)
    this.getFileAnnotations();
    trackPublicActivity('Page Visited', { page_title: 'Shared', page_type: 'Public' })
  }
  getFileAnnotations = () => {
    const { uuid } = this.props.match.params;
    let loggedInUser=getLocalStorageInfo()?.user?getLocalStorageInfo().user:null;
    if (loggedInUser){
        globalGetService(`records/annotations/${uuid}/`)
        .then(response => {
          this.setState({annotations:[], annotationLoader:false});
          if(checkApiStatus(response)){
            this.setState({annotations:response?.data?.data||[], annotationLoader:false});
          }
        })
    }else{
        this.setState({annotations:[], annotationLoader:false});
    }
  }
  downloadFile = (url, name) => {
    this.setState({ pageLoader: true })
    axios.get(url, { responseType: 'arraybuffer', })
      .then(response => {
        this.setState({ pageLoader: false })
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click()
      })
      .catch(error => this.setState({ pageLoader: false }))
  }
  toggleAnnotation = () => {
    this.setState({isFileAnnotationError:true});
    setTimeout(() => { this.setState({isFileAnnotationError:false})}, 10)
  }
  render() {
    const { fileInfo, pageLoader, addEditIssue, annotations, annotationLoader, isFileAnnotationError } = this.state;
    return (
      <section className="file-preview-section">
        <div className="header-section">
          <div className="logo-row">
            <img src="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/sparta_logo.png" alt="Sparta" />
          </div>
          {fileInfo ?
            <Paper square className='file-information'>
              <Grid container spacing={1} alignItems='center'>
                <Grid item md={7}>
                  <h4>{fileInfo.name}</h4>
                </Grid>
                <Grid item md={5}>
                  <ul className='list-inline flex-centered'>
                    {checkPermission('records','data_room','DN') ?
                      <li title="Download original file" className='list-inline-item'>
                        <a onClick={() => {
                          this.downloadFile(decodeURIComponent(fileInfo.path), fileInfo.name)
                          if (window.location.href.includes('shared')) {
                            this.trackFileDownloadActivity(fileInfo, fileInfo.share_key)
                          } else {
                            this.trackFileDownloadActivity(fileInfo, fileInfo.uuid)
                          }
                        }} download={fileInfo.name}>
                          <CloudDownloadIcon color="primary" />
                        </a>
                      </li>
                      :null
                    }
                    {checkPermission('records','comments_page','R') ?
                      <li className='list-inline-item'>
                        <Comments fileInfo={fileInfo}/>
                      </li>:null
                    }
                    {checkPermission('records','records_issue','R') && fileInfo?.issues_count ?
                      <li className='list-inline-item'>
                        <LinkedIssues
                          count={fileInfo?.issues_count||0} 
                          assetSlug={fileInfo?.asset_details?.asset_slug||null}
                          assetType={fileInfo?.asset_details?.asset_type||null}
                          getResponseBack={() => this.filePreviewDetailApi(this.props, this.props.match.params.uuid, 'issueUpdate')}
                        />
                      </li>:null
                    }
                    {checkPermission('records','records_issue','C') ?
                      <li className='list-inline-item'>
                        <Button onClick={() => this.setState({addEditIssue:{modal:true,mode:'add', data:addNewIssueObj}})} color='primary' size='small' variant='contained'>Add Issue</Button>
                      </li>:null
                    }
                    { checkPermission('records','records_issue','U') ?
                      <li className='list-inline-item'>
                        <UnlinkedIssues 
                          assetSlug={fileInfo?.asset_details?.asset_slug||null}
                          assetType={fileInfo?.asset_details?.asset_type||null}
                          getResponseBack={() => this.filePreviewDetailApi(this.props, this.props.match.params.uuid, 'issueUpdate')}
                        />
                      </li>:null
                    }
                  </ul>
                </Grid>
              </Grid>
            </Paper>
            :null
          }
        </div>
        {fileInfo ?
          <div>
            {['pdf', 'xls', 'xlsx', 'doc', 'docx', 'ppt', 'pptx', ...imageMedia, ...videoTypes].includes(fileInfo.extension.toLowerCase()) ?
              <Fragment>
                {fileInfo.extension.toLowerCase() === 'pdf' ?
                  <>
                    {!annotationLoader ?
                        <div>
                            {!isFileAnnotationError ?<AdobePdfViewer toggleAnnotation={this.toggleAnnotation} fileInfo={fileInfo}  annotations={annotations} />:null}
                        </div>:null
                    }
                    <div style={{display:'none'}}>
                      <object type="text/html" data={`https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/pdf.js/web/viewer.html?file=${fileInfo.path}#search=${window.location.search.substr(window.location.search.indexOf('?searchTerm=') + 12)}`} style={{ width: '100%', height: window.innerHeight - 100 }}>
                        <param name="type" value="text/html" />
                      </object>
                    </div>
                  </>
                   : null
                }
                {['csv', 'xls', 'xlsx', 'doc', 'docx', 'ppt', 'pptx'].includes(fileInfo.extension.toLowerCase()) ?
                  <div>
                    <iframe src={'https://view.officeapps.live.com/op/embed.aspx?src=' + fileInfo.path} width='100%' height={window.innerHeight} frameborder='0'></iframe>
                  </div> : null
                }
                {imageMedia.includes(fileInfo.extension.toLowerCase()) ?
                  <div style={{ textAlign: 'center' }}>
                    <img style={{ margin: '0 auto', maxHeight: window.innerHeight, maxWidth: window.innerWidth }} src={decodeURIComponent(fileInfo.path)} alt="" />
                  </div> : null
                }
                {videoTypes.includes(fileInfo.extension.toLowerCase()) ?
                  <div style={{ textAlign: 'center', width: '100%', margin: '0 auto' }}>
                    <video controls width='80%'>
                      <source src={decodeURIComponent(fileInfo.path)} type="video/mp4" />
                      Sorry, your browser doesn't support embedded videos.
                    </video>
                  </div> : null
                }
              </Fragment> :
              <div className="not-supported-preview" style={{ textAlign: 'center' }}>
                <img src={disabledFileIcon} alt="" />
                <h4>This file type is not supported by the native Viewer.</h4>
                <p>It may be possible to export the file to your local workstation and open it with another application</p>
                <a href={decodeURIComponent(fileInfo.path)} download><Button color="primary" variant="contained">Export</Button></a>
              </div>
            }
          </div> : null
        }
        { addEditIssue.modal && fileInfo?.asset_details?.asset_slug ?
            <AddIssue 
                assetSlug={fileInfo?.asset_details?.asset_slug||null}
                assetType={fileInfo?.asset_details?.asset_type||null}
                uuid={this.props.match.params.uuid}
                recordType={'file'}
                addEditIssue={addEditIssue}
                toggleModalFn={() => this.setState({addEditIssue:{ modal: false, mode: '', data: null }})}
                getResponseBack={() => this.filePreviewDetailApi(this.props, this.props.match.params.uuid)}
            />:null
        }
        { this.state.pageLoader ? <PageLoader /> : null }
      </section>
    )
  }
}
export default withSnackbar(FilePreview);
