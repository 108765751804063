import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import { Button, Grid, Paper, Tooltip, Switch } from '@material-ui/core';
import { UploadTemplate } from '../components';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { PageLoader, NoAccess } from '../../../shared_elements';
import folderIcon from '../../../assets/images/folder_icon.svg';
import { getLessorTemplatesApi, addEditTemplateApi, autoPublishSettingApi, downloadLessorTemplateApi } from '../apiServices';
import { trackActivity } from '../../../utils/mixpanel';
import { checkPermission } from '../../../utils';
export const assetTypeValues = {
  1: { label: 'Aircraft', template: 'https://sparta-aims.ams3.digitaloceanspaces.com/media/folder_structure/Sample%20Aircraft%20Template.xlsx' },
  2: { label: 'Engine & Modules', template: 'https://sparta-aims.ams3.digitaloceanspaces.com/media/folder_structure/Sample%20Engine%20&%20Modules%20Template.xlsx' },
  3: { label: 'APU', template: 'https://sparta-aims.ams3.digitaloceanspaces.com/media/folder_structure/Sample%20APU%20Template.xlsx' },
  4: { label: 'Landing Gear', template: 'https://sparta-aims.ams3.digitaloceanspaces.com/media/folder_structure/Sample%20LG%20Template.xlsx' },
  5: { label: 'Propeller', template: 'https://sparta-aims.ams3.digitaloceanspaces.com/media/folder_structure/Sample%20Propeller%20Template.xlsx' }
};
class Templates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skeletonLoader: false,
      pageLoader: false,
      file_auto_publish: false,
      templateUpdate: { modal: false, data: null },
      templates: []
    }
    this.getLessorTemplatesApi = getLessorTemplatesApi.bind(this);
    this.addEditTemplateApi = addEditTemplateApi.bind(this);
    this.autoPublishSettingApi = autoPublishSettingApi.bind(this);
    this.downloadLessorTemplateApi = downloadLessorTemplateApi.bind(this);
  }
  componentDidMount() {
    if(checkPermission('settings','records_template','R')){
      this.getLessorTemplatesApi(this.props);
    }
    trackActivity('Page Visited', {
      page_title: 'Settings(Data Room Templates)'
    })
  }
  render() {
    const { skeletonLoader, templateUpdate, file_auto_publish, pageLoader, templates } = this.state;
    if(checkPermission('settings','enable_auto-publish_file(s)','R') || checkPermission('settings','records_template','R')){
      return (
        <section className="records-templates">
          { checkPermission('settings','enable_auto-publish_file(s)','R') ?
            <div className="heading">
              <h3>Data Room Templates</h3>
              <p>
                {file_auto_publish ? 'Disable' : 'Enable'} Auto-Publish File(s)
                <Switch
                  disabled={!checkPermission('settings','enable_auto-publish_file(s)','U')}
                  checked={file_auto_publish}
                  onChange={(e) => {this.autoPublishSettingApi(this.props, { file_auto_publish: e.target.checked });trackActivity('Item Clicked', { page_title: 'Settings', event_type: 'Toggle bar switched', event_desc: ' Switched the Auto Publish Toggle bar from Records Template in Settings Page '}) }}
                  name="checkedB"
                  color="primary"
                />
              </p>
              {!file_auto_publish ? <span className="notes">Allow Auto-Publish will help to reduce the manual approval process</span> : null}
            </div>:null
          }
          
          
          { checkPermission('settings','records_template','R') ?
            <Grid container spacing={2}>
              {templates.map((item) =>
                <Grid item xs={12} sm={12} md={4}>
                  <Paper className="template-card">
                    <h4 className="flex-centered"><img width="26" src={folderIcon} alt="Folder" /> {assetTypeValues[item.asset_type].label}</h4>
                    <ul className="list-inline">
                      {checkPermission('settings','records_template','UP') ?
                        <li className="list-inline-item">
                          <Tooltip title="Upload Template" arrow>
                            <CloudUploadIcon onClick={() => {this.setState({ templateUpdate: { modal: true, data: item } }); trackActivity('Item Clicked', { page_title: 'Settings', event_type: 'Upload Template Clicked', event_desc: ` Clicked on Upload Template from Records Template in Settings secondary sidebar `})}} color="primary" />
                          </Tooltip>
                        </li>:null
                      }
                      
                      {item.id && item.template_sheet && checkPermission('settings','records_template','DN') ?
                        <li className="list-inline-item">
                          <Tooltip title="Download Template" arrow>
                            <a onClick={() => {this.downloadLessorTemplateApi(this.props, item.id, assetTypeValues[item.asset_type].label);trackActivity('Item Clicked', { page_title: 'Settings', event_type: 'Download Template Clicked', event_desc: ' Clicked on Downlaod Template from Records Template in Settings Page '}) }}><CloudDownloadIcon color="primary" /></a>
                          </Tooltip>
                        </li> : null
                      }
                    </ul>
                    {!item.template_sheet ?
                      <p>
                        Please refer sample document
                        <a href={assetTypeValues[item.asset_type].template}>Download</a>
                      </p> : null
                    }
  
                  </Paper>
                </Grid>
              )}
            </Grid>:null
          }
          
          {templateUpdate.modal ?
            <UploadTemplate
              templateUpdate={templateUpdate}
              toggleModalFn={() => this.setState({ templateUpdate: { modal: false, data: null } })}
              uploadTemplateFile={(file) => this.addEditTemplateApi(this.props, file, templateUpdate)}
            /> : null
          }
          {pageLoader ? <PageLoader /> : null}
        </section>
      )  
    }else{
      return <NoAccess/>
    }
  }
}
export default withSnackbar(Templates);
