import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom'
import Skeleton from '@material-ui/lab/Skeleton';
import { Button, Chip, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, TextField, Divider, ListItemSecondaryAction } from '@material-ui/core';
import { globalDeleteService, globalExportService, globalGetService, globalPostService, globalPutService } from '../../../utils/globalApiServices';
import { checkApiStatus, checkPermission, downloadFileType, localTimeFn } from '../../../utils';
import { DeletePopUp, PageLoader } from '../../../shared_elements';
import { ChecklistFileLinking, ChecklistLinkedFiles } from '../components'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FindReplaceIcon from '@material-ui/icons/FindReplace';
import FolderIcon from '@material-ui/icons/Folder';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
const LookUpResult = ({lookUpInfo, toggleModalFn, match, history}) => {

    return(
        <Dialog
                open={lookUpInfo.modal}
                onClose={toggleModalFn}
                className='records-dataroom-modal'
                maxWidth='md'
        >
            <DialogTitle id="scroll-dialog-title">Lookup Result: {lookUpInfo?.checklist?.name||''} </DialogTitle>
            <DialogContent dividers={true}>
                <div style={{width:'480px'}}>
                    {lookUpInfo?.checklist?.simulation_data?.length && lookUpInfo.checklist.simulation_data.map((item) => 
                        <>
                            <div style={{padding:'5px'}}>
                                <h4 style={{textDecoration:'underline', cursor:'pointer'}} className='flex-centered' onClick={() => item.type==='folder'? window.open(`/${match.params.assetType}/${match.params.assetSlug}/data-room/${item.category}/${item.uuid}`, '_blank'):window.open(`/records/preview/${item.uuid}`, '_blank')}>{item.type==='folder'?<FolderIcon fontSize='small' color='primary' />:<InsertDriveFileIcon fontSize='small' color='primary'/>}<span style={{fontSize:'13px', fontWeight:'500'}}>{item.name}</span></h4>
                                {item.path ?
                                    <p style={{fontSize:'11px', fontWeight:'500'}}>{item.path}</p>:null
                                }
                            </div>
                            <Divider/>
                        </>
                    )}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={toggleModalFn} size='small' color="primary" variant='outlined'>Close</Button>
            </DialogActions>
        </Dialog>
    )
}
const AddEditSection = ({match, addEditSection, getResponseBack, toggleModalFn}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [section, setSection] = useState(addEditSection.data);
    const [error, setError] = useState({});
    const onAddEditSection = () => {
        const validationInputs = {
            name:section?.name?.trim()?.length ? '':'Please enter Name',
        }
        if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
            if(section.id){
                let url = `records/${match.params.assetType}/${match.params.assetSlug}/${addEditSection.type === 'section' ?'checklist-sections':'checklist-sub-sections'}/${section.id}/`; 
                setLoading(true)
                globalPutService(url, section)
                .then(response => {
                    if(checkApiStatus(response)){
                        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                        toggleModalFn();getResponseBack();
                    }else{
                        enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
                    }
                    setLoading(false)
                })
            }else{
                let url = `records/${match.params.assetType}/${match.params.assetSlug}/${addEditSection.type === 'section' ?'checklist-sections':'checklist-sub-sections'}/`;
                setLoading(true)
                globalPostService(url, [{...section, inspection:match.params.slug}])
                .then(response => {
                    if(checkApiStatus(response)){
                        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                        toggleModalFn();getResponseBack();
                    }else{
                        enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
                    }
                    setLoading(false)
                })
            }
        }else{
            setError(validationInputs)
        }
    }
    return(
        <>
            <Dialog
                open={addEditSection.modal}
                onClose={toggleModalFn}
                className='records-dataroom-modal'
                maxWidth='md'
            >
                <DialogTitle id="scroll-dialog-title"> {section?.id ? 'Edit':'Add'} {addEditSection.type === 'section' ? 'Section':'Sub-Section'} </DialogTitle>
                <DialogContent dividers={true}>
                    <Grid container spacing={1}>
                        <Grid item md={12}>
                            <TextField
                                required
                                label="Name"
                                name="name"
                                fullWidth
                                margin="normal"
                                value={section?.name||''}
                                error={error?.name}
                                helperText={error?.name ||''}
                                inputProps={{ maxLength: 250}}
                                onChange={(e) => setSection({...section,name:e.target.value})}
                                onFocus={() => setError({...error, name:''})}
                                InputLabelProps={{shrink: true}}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                label="Description"
                                name="description"
                                fullWidth
                                margin="normal"
                                multiline={true}
                                rows={3}
                                value={section?.description||''}
                                onChange={(e) => setSection({...section,description:e.target.value})}
                                InputLabelProps={{shrink: true}}
                                variant='outlined'
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggleModalFn} disabled={isLoading} size='small' color="primary" variant='outlined'>Cancel</Button>
                    <Button onClick={onAddEditSection} disabled={isLoading} size='small' color="primary" variant='contained'>
                        {isLoading ? <CircularProgress size={24} />:'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
const AddEditCheckList = ({match, toggleModalFn, getResponseBack, addEditCheckList}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [checklist, setChecklist] = useState(addEditCheckList.data);
    const [error, setError] = useState({});
    const onAddEditChecklist = () => {
        const validationInputs = {
            name:checklist?.name?.trim()?.length ? '':'Please enter Name',
            notes:checklist?.status === -1 ? checklist?.notes?.trim()?.length ? '':'Please enter Remarks':''
        }
        if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
            if(checklist.id){
                setLoading(true)
                globalPutService(`records/${match.params.assetType}/${match.params.assetSlug}/checklist-items/${checklist.id}/`, checklist)
                .then(response => {
                    if(checkApiStatus(response)){
                        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                        toggleModalFn();getResponseBack();
                    }else{
                        enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
                    }
                    setLoading(false)
                })
            }else{
                setLoading(true)
                globalPostService(`records/${match.params.assetType}/${match.params.assetSlug}/checklist-items/`,[checklist])
                .then(response => {
                    if(checkApiStatus(response)){
                        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                        toggleModalFn();getResponseBack();
                    }else{
                        enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
                    }
                    setLoading(false)
                })
            }
        }else{
            setError(validationInputs)
        }
    }
    return(
        <>
            <Dialog
                open={addEditCheckList.modal}
                onClose={toggleModalFn}
                className='records-dataroom-modal'
                maxWidth='md'
            >
                <DialogTitle id="scroll-dialog-title"> {checklist?.id ? 'Edit':'Add'} Checklist </DialogTitle>
                <DialogContent dividers={true}>
                    <Grid container spacing={1}>
                        <Grid item md={12}>
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item md={6}>
                                    <TextField
                                        required
                                        label="Name"
                                        name="name"
                                        fullWidth
                                        margin="normal"
                                        value={checklist?.name||''}
                                        error={error?.name}
                                        inputProps={{ maxLength: 250}}
                                        helperText={error?.name ||''}
                                        onChange={(e) => setChecklist({...checklist,name:e.target.value})}
                                        onFocus={() => setError({...error, name:''})}
                                        InputLabelProps={{shrink: true}}
                                        variant='outlined'
                                    />
                                </Grid>
                                <Grid item md={6}>
                                    <h5>Status</h5>
                                    <ul className='list-inline'>
                                        <li className='list-inline-item' onClick={() => setChecklist({...checklist,status:1})} style={{cursor:'pointer'}}>
                                            <Tooltip title='Received' arrow>
                                                <CheckCircleIcon style={{color:checklist.status === 1 ? 'rgb(39, 120, 49)':'#8b8b8b'}} fontSize='medium' />
                                            </Tooltip>
                                        </li>
                                        <li className='list-inline-item' onClick={() => setChecklist({...checklist,status:2})} style={{cursor:'pointer'}}>
                                            <Tooltip title='Pending' arrow>
                                                <CancelIcon style={{color:checklist.status === 2 ? 'rgb(143, 80, 80)':'#8b8b8b'}} fontSize='medium' />
                                            </Tooltip>
                                        </li>
                                        <li className='list-inline-item' onClick={() => setChecklist({...checklist,status:-1})} style={{cursor:'pointer'}}>
                                            <Tooltip title='N/A' arrow>
                                                <RemoveCircleIcon style={{color:checklist.status === -1 ? 'rgb(173, 121, 0)':'#8b8b8b'}} fontSize='medium' />
                                            </Tooltip>
                                        </li>
                                    </ul>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                label="Description"
                                name="description"
                                fullWidth
                                margin="normal"
                                multiline={true}
                                rows={3}
                                value={checklist?.description||''}
                                onChange={(e) => setChecklist({...checklist,description:e.target.value})}
                                InputLabelProps={{shrink: true}}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                required={checklist.status === -1 ? true:false}
                                label="Remarks"
                                name="notes"
                                fullWidth
                                margin="normal"
                                multiline={true}
                                rows={3}
                                value={checklist?.notes||''}
                                error={error?.notes}
                                helperText={error?.notes ||''}
                                onChange={(e) => setChecklist({...checklist,notes:e.target.value})}
                                onFocus={() => setError({...error, notes:''})}
                                InputLabelProps={{shrink: true}}
                                variant='outlined'
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggleModalFn} disabled={isLoading} size='small' color="primary" variant='outlined'>Cancel</Button>
                    <Button onClick={onAddEditChecklist} disabled={isLoading} size='small' color="primary" variant='contained'>
                        {isLoading ? <CircularProgress size={24} />:'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
const CheckListHd = () => {
    return(
        <TableHead>
            <TableRow>
                <TableCell>Document Type</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Remarks</TableCell>
                <TableCell>Lookup Status</TableCell>
                <TableCell align='right'>Action</TableCell>
            </TableRow>
        </TableHead>
    )
}
const CheckListItem = ({item, isEdit, onEdit, onDelete, onChangeStatus, onLookup, onFileLink, onOpenLoopUp, getResponseBack}) => {
    return(
        <TableRow>
            <TableCell>
                <div style={{width:'380px'}}>
                    <h5>{item?.name||''}</h5>
                    {item?.description ? <p className='doc-description'>{item.description}</p>:null }
                    <ul className='list-inline'>
                        { checkPermission('records','inspection_checklist','U') && isEdit?
                            <li className='list-inline-item'>
                                <Chip onClick={onFileLink} label={'Link File(s)'} size='small' color='primary' variant='outlined'/>
                            </li>:null
                        }
                        {item?.linked_files ?
                            <li className='list-inline-item'>
                                <ChecklistLinkedFiles isEdit={isEdit} item={item} getResponseBack={getResponseBack} />
                            </li>:null
                        }
                    </ul>
                </div>
            </TableCell>
            <TableCell>
                <div style={{width:'180px'}}>
                    <ul className='list-inline'>
                        <li className='list-inline-item' onClick={(e) => checkPermission('records','inspection_checklist','U') && isEdit ? onChangeStatus(1):e.preventDefault()} style={{cursor:isEdit?'pointer':'default'}}>
                            <Tooltip title='Received' arrow>
                                <CheckCircleIcon style={{color:item.status === 1 ? 'rgb(39, 120, 49)':'#8b8b8b'}} fontSize='medium' />
                            </Tooltip>
                        </li>
                        <li className='list-inline-item' onClick={(e) => checkPermission('records','inspection_checklist','U') && isEdit ? onChangeStatus(2):e.preventDefault()} style={{cursor:isEdit?'pointer':'default'}}>
                            <Tooltip title='Pending' arrow>
                                <CancelIcon style={{color:item.status === 2 ? 'rgb(143, 80, 80)':'#8b8b8b'}} fontSize='medium' />
                            </Tooltip>
                        </li>
                        <li className='list-inline-item' onClick={(e) => checkPermission('records','inspection_checklist','U') && isEdit ? onChangeStatus(-1):e.preventDefault()} style={{cursor:isEdit?'pointer':'default'}}>
                            <Tooltip title='N/A' arrow>
                                <RemoveCircleIcon style={{color:item.status === -1 ? 'rgb(173, 121, 0)':'#8b8b8b'}} fontSize='medium' />
                            </Tooltip>
                        </li>
                    </ul>
                </div>
            </TableCell>
            <TableCell>{item?.notes||'--'}</TableCell>
            <TableCell>
                <div style={{width:'180px'}}>
                    {item?.platform_status === null ? '--' : 
                        <>
                            {item?.last_simulation_by?.name ?
                                <div>
                                    <p style={{fontSize:'11px'}}>Last Performed By:</p>
                                    <h5>{item?.last_simulation_by?.name||'--'}</h5>
                                    {item.last_simulation_at ?<p style={{fontSize:'11px'}}>{localTimeFn(item.last_simulation_at)}</p>:null}
                                </div>:null
                            }
                            <span onClick={(e) => item?.simulation_data?.length? onOpenLoopUp():e.preventDefault()} style={{border:'1px solid #3f51b5', padding:'5px', borderRadius:'10px', fontSize:'13px', color:'#3f51b5', display:'inline-block', cursor: item?.simulation_data?.length ? 'pointer':'default'}}>{`Match Found (${item?.simulation_data?.length})`}</span>
                        </>
                    }
                </div>
            </TableCell>
            <TableCell align='right'>
                <div style={{width:'80px', float:'right'}}>
                    <ul className='list-inline'>
                        {checkPermission('records','inspection_checklist','U') && isEdit ?
                            <>
                                <li className='list-inline-item' onClick={onEdit} style={{cursor:'pointer'}}>
                                    <Tooltip title='Edit' arrow><EditIcon fontSize='small' color='primary' /></Tooltip>
                                </li>
                                <li className='list-inline-item' onClick={onDelete} style={{cursor:'pointer'}}>
                                    <Tooltip title='Delete' arrow><DeleteOutlineIcon fontSize='small' color='secondary' /></Tooltip>
                                </li>
                                <li className='list-inline-item' onClick={onLookup} style={{cursor:'pointer'}}>
                                    <Tooltip title='Lookup Document' arrow>
                                        <FindReplaceIcon fontSize='small' color='primary' />    
                                    </Tooltip>
                                </li>
                            </>:null
                        }
                    </ul>
                </div>
            </TableCell>
        </TableRow>
    )
} 
const DataCheckListDetail = ({match, history}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [checklistDetail, setChecklistDetail] = useState({});
    const [addEditCheckList, setAddEditCheckList] = useState({modal:false, data:null})
    const [addEditSection, setAddEditSection] = useState({modal:false, type:'', data:null})
    const [deleteCheckList, setDeleteCheckList] = useState({modal:false, data:null});
    const [deleteSection, setDeleteSection] = useState({modal:false, type:'', data:null});
    const [fileLinkInfo, setFileLinkInfo] = useState({modal:false, data:null});
    const [lookUpInfo, setLookUpInfo] = useState({modal:false, checklist:null});
    useEffect(() => {
        getInspectionCheckList({}, 'skeletonLoader');
    },[]);
    const getInspectionCheckList = (query={}, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true):setLoading(true);
        globalGetService(`records/${match.params.assetType}/${match.params.assetSlug}/checklist-inspection/${match.params.slug}/`)
        .then(response => {
            if(checkApiStatus(response)){
                setChecklistDetail(response.data.data)
            }
            loaderType === 'skeletonLoader' ? setSkeletonLoader(false):setLoading(false);
        })
    }
    const onEditChecklist = (item, status) => {
        if(status === -1 && !item?.notes?.trim()?.length){
            setAddEditCheckList({modal:true, data:{...item, status}});
            return;
        }
        let payload = {...item, status };
        globalPutService(`records/${match.params.assetType}/${match.params.assetSlug}/checklist-items/${item.id}/`, payload)
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                getInspectionCheckList({}, 'pageLoader')
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
            }
        })
    }
    const onDeleteCheckList = () => {
        setLoading(true)
        globalDeleteService(`records/${match.params.assetType}/${match.params.assetSlug}/checklist-items/${deleteCheckList.data.id}/`)
        .then(response => {
            if(checkApiStatus(response)){
                setDeleteCheckList({modal:false, data:null});
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                getInspectionCheckList({}, 'pageLoader')
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
            }
            setLoading(false)
        })
    }
    const onDeleteSection = () => {
        setLoading(true)
        let url = `records/${match.params.assetType}/${match.params.assetSlug}/${deleteSection.type === 'section' ?'checklist-sections':'checklist-sub-sections'}/${deleteSection.data.id}/`
        globalDeleteService(url)
        .then(response => {
            if(checkApiStatus(response)){
                setDeleteSection({modal:false, type:'', data:null});
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                getInspectionCheckList({}, 'pageLoader')
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
            }
            setLoading(false)
        })
    }
    const onLoopUpInspection = (query={}, checklist) => {
        setLoading(true);
        globalGetService(`records/${match.params.assetType}/${match.params.assetSlug}/checklist-simulate/${match.params.slug}/`,query)
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                if(query.checklist){
                    if(response?.data?.data?.list?.length){
                        setLookUpInfo({modal:true, checklist:{...checklist, simulation_data:response.data.data.list}})
                    }else{
                        enqueueSnackbar('No match found', { variant: 'info',anchorOrigin:{horizontal:'right', vertical:'top'}});
                    }
                }
                getInspectionCheckList({}, 'pageLoader')
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
            }
            setLoading(false);
        })
    }
    const getChecklistStatusCount = (section, statusType) => {
        let count=0, total=0;
        count += section?.checklist ? section.checklist.filter(item => item.status === statusType).length:0;
        total += section?.checklist ? section.checklist.length:0;
        if(section.has_sub_section){
            section.sub_section.map((sbSec) => {
                if(sbSec.checklist){
                    count += sbSec.checklist ? sbSec.checklist.filter(item => item.status === statusType).length:0;
                    total += sbSec.checklist ? sbSec.checklist.length:0;
                }else{
                    count +=0;
                    total +=0;
                }
            })
        }
        return {count, total: count ? ((count/total)*100).toFixed(2):0}
    }
    const updateCheckListCount = (sectionId, itemId, subSecId) => {
        if(subSecId){
            setChecklistDetail({...checklistDetail, sections:checklistDetail.sections.map(section => section.id !== sectionId ? section:{
                ...section, sub_section: section.sub_section.map(sbSec => sbSec.id !== subSecId ? sbSec: {
                    ...sbSec, checklist:sbSec.checklist.map(checklist => checklist.id !== itemId ? checklist:{
                        ...checklist,'linked_files':checklist.linked_files-1
                    })
                })
            })})
        }else{
            setChecklistDetail({...checklistDetail, sections:checklistDetail.sections.map(section => section.id !== sectionId ? section:{
                ...section, checklist:section.checklist.map(checklist => checklist.id !== itemId ? checklist :{...checklist,'linked_files':checklist.linked_files-1})
            })})
        }
    }
    const onExportReport = (fileName) => {
        setLoading(true);
        globalExportService(`records/${match.params.assetType}/${match.params.assetSlug}/checklist-inspection/${match.params.slug}/`,{download:'xlsx'})
        .then(response => {
            downloadFileType(response.data,fileName?fileName:'Inspection_checklist', 'xlsx');
            setLoading(false);
        })
    }
    return(
        <section className='data-room-file-checklists'>
            <>
                {skeletonLoader ?
                    <>
                        <Skeleton variant="text" /><br/>
                        <Skeleton variant="rect" width={'100%'} height={480} />
                    </>:
                    <>
                        <div className='checklists-hd-cta'>
                            <Grid container spacing={1}>
                                <Grid item md={6}>
                                    <div className='flex-centered'>
                                        <Link to={`/${match.params.assetType}/${match.params.assetSlug}/data-room/checklist`}> <ArrowBackIcon fontSize='small' color='primary' /> </Link>
                                        <div>
                                            <h2>{checklistDetail?.name||''}</h2>
                                            {checklistDetail?.status?.label ?
                                                <span className={`checklist-status ${checklistDetail.status.label.toLowerCase()}`}>{checklistDetail.status.label}</span>:null
                                            }
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item md={6}>
                                    <ul className='list-inline' style={{float:'right'}}>
                                        { checkPermission('records','inspection_checklist','U') && checklistDetail?.status?.value === 1 ?
                                            <li className='list-inline-item'>
                                                <Button onClick={() => setAddEditSection({modal:true, type:'section', data:{name:'', description:''}})} size='small' color='primary' variant='outlined'>Add Section</Button>
                                            </li>:null
                                        }
                                        { checkPermission('records','inspection_checklist','EXP') ?
                                            <li className='list-inline-item'>
                                                <Button onClick={() => onExportReport(checklistDetail?.name||'')} size='small' color='primary' variant='outlined'>Export</Button>
                                            </li>:null
                                        }
                                        
                                        { checkPermission('records','inspection_checklist','U') && checklistDetail?.status?.value === 1 && !checklistDetail?.simulation_flag ?
                                            <li className='list-inline-item'>
                                                <Button onClick={() => onLoopUpInspection()} size='small' color='primary' variant='contained' startIcon={<FindReplaceIcon fontSize='small' />}>Lookup</Button>
                                            </li>:null
                                        }
                                    </ul>
                                </Grid>
                            </Grid>
                        </div>
                        {checklistDetail?.sections?.length ?
                            <>
                                {checklistDetail.sections.map((section) => 
                                    <div className='document-sections'>
                                        <Paper className='doc-sec-head' square elevation={1}>
                                            <Grid container spacing={0}>
                                                <Grid item md={6}>
                                                    <div>
                                                        <h4>{section.name}</h4>
                                                        <p>{section.description}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item md={6}>
                                                    <ul className='list-inline'>
                                                        <li className='list-inline-item'>
                                                            {(() => {
                                                                let itemStats = getChecklistStatusCount(section, 1);
                                                                return(
                                                                    <Chip size='small' label={`Received: ${itemStats.count} (${itemStats.total}%)`} style={{background: 'rgb(39, 120, 49)', color: 'rgb(255, 255, 255)', width: '150px'}} />
                                                                )
                                                            })()}
                                                        </li>
                                                        <li className='list-inline-item'>
                                                            {(() => {
                                                                let itemStats = getChecklistStatusCount(section, 2);
                                                                return(
                                                                    <Chip size='small' label={`Pending: ${itemStats.count} (${itemStats.total}%)`} style={{background: 'rgb(143, 80, 80)', color: 'rgb(255, 255, 255)', width: '150px'}} />
                                                                )
                                                            })()}
                                                            
                                                        </li>
                                                        <li className='list-inline-item'>
                                                            {(() => {
                                                                let itemStats = getChecklistStatusCount(section, -1);
                                                                return(
                                                                    <Chip size='small' label={`N/A: ${itemStats.count} (${itemStats.total}%)`} style={{background: 'rgb(173, 121, 0)', color: 'rgb(255, 255, 255)', width: '150px'}} />
                                                                )
                                                            })()}
                                                        </li>
                                                        { checkPermission('records','inspection_checklist','U') && checklistDetail?.status?.value === 1 ?
                                                            <>
                                                                <li className='list-inline-item' onClick={() => setAddEditSection({modal:true, type:'section', data:section})}>
                                                                    <Tooltip title='Edit Section' arrow><EditIcon fontSize='small' color='primary' /></Tooltip>
                                                                </li>
                                                                <li className='list-inline-item' onClick={() => setDeleteSection({modal:true, type:'section', data:{id:section.id}})}>
                                                                    <Tooltip title='Delete Section' arrow><DeleteOutlineIcon fontSize='small' color='secondary' /></Tooltip>
                                                                </li>
                                                            </>:null
                                                        }
                                                        {checkPermission('records','inspection_checklist','U') && checklistDetail?.status?.value === 1 && (!checklistDetail?.simulation_flag && !section.simulation_flag) ?
                                                            <li className='list-inline-item' onClick={() => onLoopUpInspection({section:section.id})}>
                                                                <Tooltip title='Lookup Section' arrow>
                                                                    <FindReplaceIcon fontSize='small' color='primary' />    
                                                                </Tooltip>
                                                            </li>:null
                                                        }
                                                    </ul>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                        <Paper square>
                                            { section.has_sub_section ?
                                                <div className='checklist-subsections'>
                                                    {checkPermission('records','inspection_checklist','U') && checklistDetail?.status?.value === 1 ?
                                                        <div style={{textAlign:'right', padding:'5px'}}>
                                                            <Button onClick={() => setAddEditSection({modal:true, type:'sub-section', data:{name:'', description:'', section_id:section.id}})} size='small' color='primary' variant='outlined'>Add Sub Section</Button>
                                                        </div>:null
                                                    }
                                                    {section?.sub_section && section.sub_section.map((subSection) => 
                                                        <>
                                                            <Paper square>
                                                                <div className='doc-sec-head flex-centered'>
                                                                    <div>
                                                                        <h4 style={{color:'#5a7dff'}}>{subSection.name}</h4>
                                                                        <p>{subSection.description}</p>
                                                                    </div>
                                                                    <ul className='list-inline' style={{marginLeft:'auto'}}>
                                                                        { checkPermission('records','inspection_checklist','U') && checklistDetail?.status?.value === 1 ?
                                                                            <>
                                                                                <li className='list-inline-item'>
                                                                                    <Tooltip title='Edit Sub-Section' arrow>
                                                                                        <EditIcon onClick={() => setAddEditSection({modal:true, type:'sub-section', data:{name:subSection.name, description:subSection.description, section_id: section.id, id: subSection.id}})} fontSize='small' color='primary' />
                                                                                    </Tooltip>
                                                                                </li>
                                                                                <li className='list-inline-item'>
                                                                                    <Tooltip title='Delete Sub-Section' arrow>
                                                                                        <DeleteOutlineIcon onClick={() => setDeleteSection({modal:true, type:'sub-section', data:{id:subSection.id}})} fontSize='small' color='secondary' />
                                                                                    </Tooltip>
                                                                                </li>
                                                                            </>:null
                                                                        }
                                                                        { checkPermission('records','inspection_checklist','U') && checklistDetail?.status?.value === 1 && (!checklistDetail?.simulation_flag && !section.simulation_flag && !subSection.simulation_flag) ?
                                                                            <li className='list-inline-item' onClick={() => onLoopUpInspection({sub_section:subSection.id})}>
                                                                                <Tooltip title='Lookup Sub-Section' arrow>
                                                                                    <FindReplaceIcon fontSize='small' color='primary' />    
                                                                                </Tooltip>
                                                                            </li>:null
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            </Paper>
                                                            <Paper square>
                                                                { checkPermission('records','inspection_checklist','U') && checklistDetail?.status?.value === 1 ?
                                                                    <div style={{textAlign:'right', padding:'5px'}}>
                                                                        <Button onClick={() => setAddEditCheckList({modal:true, data:{section_id:section.id, of_sub_section:true, sub_section_id:subSection.id, name:'', notes:'',description:'', status:null}})} size="small" variant='outlined' color='primary'>Add Checklist</Button>
                                                                    </div>:null
                                                                }
                                                                <Table className='mui-table-format'>
                                                                    <CheckListHd />
                                                                    <TableBody>
                                                                        {subSection?.checklist && subSection.checklist.map((item) => 
                                                                            <CheckListItem 
                                                                                onOpenLoopUp={() => setLookUpInfo({modal:true, checklist:item})} 
                                                                                isEdit={checklistDetail?.status?.value === 1 ? true:false}
                                                                                onChangeStatus={(status) => onEditChecklist(item, status)}
                                                                                onEdit={() => setAddEditCheckList({modal:true, data:item})} 
                                                                                onDelete={() => setDeleteCheckList({modal:true, data:item})}
                                                                                item={item} 
                                                                                onLookup={() => onLoopUpInspection({checklist:item.id}, item)}
                                                                                onFileLink={() => setFileLinkInfo({modal:true, data:{id:item.id ,uuid:checklistDetail?.technical_parent||null}})}
                                                                                getResponseBack={() => updateCheckListCount(section.id, item.id, subSection.id)}
                                                                            />
                                                                        )}
                                                                    </TableBody>
                                                                </Table>
                                                                {!subSection?.checklist?.length ? <p style={{textAlign:'center', padding:'10px 0', background:'#e7e7e7'}}>No records found</p>:null}
                                                            </Paper>
                                                        </>
                                                    )}
                                                </div>:null
                                            }
                                            <div>
                                                { checkPermission('records','inspection_checklist','U') && checklistDetail?.status?.value === 1 ?
                                                    <div style={{textAlign:'right', padding:'5px'}}>
                                                        <Button onClick={() => setAddEditCheckList({modal:true, data:{section_id:section.id, of_sub_section:false, name:'', notes:'',description:'', status:null}})} size="small" variant='outlined' color='primary'>Add Checklist</Button>
                                                    </div>:null
                                                }
                                                { section?.checklist ?
                                                    <Table className='mui-table-format'>
                                                        <CheckListHd />
                                                        <TableBody>
                                                            {section.checklist.map((item) => 
                                                                <CheckListItem 
                                                                    onOpenLoopUp={() => setLookUpInfo({modal:true, checklist:item})} 
                                                                    isEdit={checklistDetail?.status?.value === 1 ? true:false}
                                                                    onChangeStatus={(status) => onEditChecklist(item, status)}
                                                                    onEdit={() => setAddEditCheckList({modal:true, data:item})} 
                                                                    onDelete={() => setDeleteCheckList({modal:true, data:item})}
                                                                    item={item} 
                                                                    onLookup={() => onLoopUpInspection({checklist:item.id}, item)}
                                                                    onFileLink={() => setFileLinkInfo({modal:true, data:{id:item.id, uuid:checklistDetail?.technical_parent||null}})}
                                                                    getResponseBack={() => updateCheckListCount(section.id, item.id)}
                                                                />
                                                            )}
                                                        </TableBody>
                                                    </Table>:null
                                                }
                                                {!section?.checklist?.length ? <p style={{textAlign:'center', padding:'10px 0', background:'#e7e7e7'}}>No records found</p>:null}
                                            </div>
                                        </Paper>
                                    </div>   
                                )}
                            </>:null
                        }   
                    </>
                }
                
                
            </>
            
            { deleteCheckList.modal ?
                <DeletePopUp
                    modal={deleteCheckList.modal}
                    toggleModalFn={() => setDeleteCheckList({modal:false, data:null})}
                    title="Delete Checklist"
                    content={<h4>Are you sure you want to delete?</h4>}
                    triggerEventFn={onDeleteCheckList}
                />:null
            }
            { deleteSection.modal ?
                <DeletePopUp
                    modal={deleteSection.modal}
                    toggleModalFn={() => setDeleteSection({modal:false, type:'', data:null})}
                    title={`Delete ${deleteSection.type === 'section'? 'Section':'Sub-Section'}`}
                    content={<h4>Are you sure you want to delete?</h4>}
                    triggerEventFn={onDeleteSection}
                />:null
            }
            { addEditCheckList.modal ?
                <AddEditCheckList 
                    toggleModalFn={() => setAddEditCheckList({modal:false, data:null})}
                    getResponseBack={() => getInspectionCheckList({}, 'pageLoader')}
                    match={match}
                    addEditCheckList={addEditCheckList}
                />:null
            }
            { addEditSection.modal ?
                <AddEditSection 
                    toggleModalFn={() => setAddEditSection({modal:false, data:null})}
                    getResponseBack={() => getInspectionCheckList({}, 'pageLoader')}
                    match={match}
                    addEditSection={addEditSection}
                />:null
            }
            {fileLinkInfo.modal ? 
                <ChecklistFileLinking
                    fileLinkInfo={fileLinkInfo}
                    toggleModalFn={() => setFileLinkInfo({modal:false, data:null})}
                    getResponseBack={() => getInspectionCheckList({}, 'pageLoader')}
                />:null
            } 
            {lookUpInfo.modal ?
                <LookUpResult 
                    lookUpInfo={lookUpInfo}
                    toggleModalFn={() => setLookUpInfo({modal:false, checklist:null})}
                    history={history}
                    match={match}
                />:null
            }
            { isLoading ? <PageLoader />:null }
        </section>
    )
}
export default DataCheckListDetail;