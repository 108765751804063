import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Grid, Checkbox, FormControlLabel, Paper } from '@material-ui/core';
import { ExportManu } from '../../../shared_elements';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { checkPermission, getLocalStorageInfo } from '../../../utils';
import activeGraphIcon from '../../../assets/images/active-pie-chart.svg'
import inactiveGraphIcon from '../../../assets/images/inactive-pie-chart.svg'
import { permissionKeys } from '../'
const DockBulkOps = ({match, ocrAnalyticFlag, filter, bulkOperation, selectAllSearch, actionDocks, onChangeBulkOps, selectOnSearch, exportReportFn, onChangeOCRAnalytic}) => {
  return(
    <div className="dock-bulk-ops">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4}>
          { !ocrAnalyticFlag && (checkPermission('reviewdock',permissionKeys[match.params.type],'PUB_REJ') || checkPermission('reviewdock',permissionKeys[match.params.type],'D') || checkPermission('reviewdock',permissionKeys[match.params.type],'RSTOR')) ?
            <Fragment>
              {filter && Object.keys(filter).length && (actionDocks.pagination.total > actionDocks.pagination.per_page) && bulkOperation.ids.length ?
                <FormControlLabel
                  control={
                    <Checkbox
                      name="bulk-sel"
                      color="primary"
                      className="checkbox-icon"
                      size="small"
                      onChange={(e) => selectOnSearch(e.target.checked)}
                    />
                  }
                  label={`Select all files that match this search (${actionDocks.pagination.total})`}
                />:null
              }
            </Fragment>:null
          }
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <ul className="list-inline dock-bulk-ops-list">
            { !ocrAnalyticFlag ?
              <Fragment>
                { bulkOperation.ids.length && match.params.type == 'rejected_queue' && checkPermission('reviewdock',permissionKeys[match.params.type],'RSTOR') ?
                  <li className="list-inline-item">
                    <Button onClick={() => onChangeBulkOps('Restore')} size="small" variant="outlined" color="primary">Restore ({!selectAllSearch ? bulkOperation.ids.length : actionDocks.pagination.total})</Button>
                  </li>:null
                }
                { bulkOperation.ids.length && checkPermission('reviewdock',permissionKeys[match.params.type],'D') ?
                  <li className="list-inline-item">
                    <Button onClick={() => onChangeBulkOps('Trash')} size="small" variant="outlined" color="secondary">Trash ({!selectAllSearch ? bulkOperation.ids.length : actionDocks.pagination.total})</Button>
                  </li>:null
                }
                { match.params.type === 'in_queue' || match.params.type === 'rename_queue' ?
                  <Fragment>
                    { bulkOperation.ids.length && checkPermission('reviewdock',permissionKeys[match.params.type],'PUB_REJ') ?
                      <Fragment>
                          <li className="list-inline-item">
                            <Button onClick={() => onChangeBulkOps('Reject')} size="small" variant="outlined" color="secondary">Reject ({!selectAllSearch ? bulkOperation.ids.length : actionDocks.pagination.total})</Button>
                          </li>
                          <li className="list-inline-item">
                            <Button onClick={() => onChangeBulkOps('Publish')} size="small" variant="outlined" color="primary">Publish ({!selectAllSearch ? bulkOperation.ids.length : actionDocks.pagination.total})</Button>
                          </li>
                      </Fragment>:null
                    }
                  </Fragment>:null
                }
                { checkPermission('reviewdock',permissionKeys[match.params.type],'EXP') ?
                  <li className="list-inline-item">
                    <ExportManu
                      title="Export"
                      files={[{title:'Excel', extension: 'xlsx', key:''}]}
                      exportReportFn={(file) => exportReportFn(file)}
                    />
                  </li>:null
                }
              </Fragment>:null
            }
            { match.params.type === 'ocr_queue' && checkPermission('reviewdock','ocr_inqueue','ANA') ?
              <Fragment>
                <li className="list-inline-item">
                  <span className="action-dock-switcher">
                    <span onClick={onChangeOCRAnalytic} className={ !ocrAnalyticFlag ? 'active':''}>
                      <ListAltIcon color={ !ocrAnalyticFlag ? 'primary':'default'} />
                    </span>
                    <span onClick={onChangeOCRAnalytic} className={ocrAnalyticFlag ? 'active':''}>
                      {ocrAnalyticFlag ? <img src={activeGraphIcon} alt="graph" />: <img src={inactiveGraphIcon} alt="graph" /> }
                    </span>
                  </span>
                </li>
              </Fragment>:null
            }
          </ul>
        </Grid>
        { match.params.type === 'ocr_queue' || match.params.type === 'ocr_rejected_queue' ?
          <Grid item xs={12}>
            <div className="action-doc-listing-wrp">
              <span className="warning-info-text" style={{marginBottom: '10px'}}>
                <p><strong>Cause of rejection:</strong></p>
                - Encrypted & Corrupted -  If file is encrypted or corrupted , then OCR scanner cannot be performed.<br/>
                - Poor quality & Handwritten document - If file has a poor resolution or handwritten content, then OCR Scanner is unable to recognise the text contained in the document.
              </span>
            </div>
          </Grid>:null
        }
      </Grid>
    </div>
  )
}
export default withRouter(DockBulkOps);
