import React, { Fragment, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { globalGetService, globalPutService } from '../../../utils/globalApiServices';
import { checkApiStatus } from '../../../utils';
import { STableLoader } from '../../../shared_elements/loaders';
import { TableListComp, EmptyCollection, PageLoader } from '../../../shared_elements';
import { CommentsList } from '../components';
import { commentDetailHd, commentDetaiFilterOps } from '..';
import FilterComponent from '../../../shared_elements/components/filter_component';
import {Paper, } from '@material-ui/core';

function CommentDetail(props) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [skeletonLoader, setSkeletonLoader] = useState(true);
    const [pageLoader, setpageLoader] = useState(false);
    const [recordComment, setRecordsComment] = useState([]);
    const [assetinfo, setAssetinfo] = useState({});
    const [filter, setFilter] = useState({});
    const [expandedComment, setExpandedComment] = useState({});

    const initialStatusAnchorEl = {};
    const [statusAnchorEl, setStatusAnchorEl] = useState(initialStatusAnchorEl);

    useEffect(() => {
        getRecordsDetailApiV_2(props);
    }, []);

    const handleStatusMenuClick = (event, commentId) => {
        setStatusAnchorEl((prevStatusAnchorEl) => ({
            ...prevStatusAnchorEl,
            [commentId]: event.currentTarget,
        }));
    };
    const handleStatusMenuClose = (commentId) => {
        setStatusAnchorEl((prevStatusAnchorEl) => ({
            ...prevStatusAnchorEl,
            [commentId]: null,
        }));
    };
    const getRecordsDetailApiV_2 = (props) => {
        globalGetService(`console/${props?.match.params.assetType}/${props?.match?.params.assetSlug}/records-detail/`)
            .then((response) => {
                if (checkApiStatus(response)) {
                    if (Object.keys(response.data.data).length) {
                        setAssetinfo({ assetinfo: response.data.data });
                        getCommentList('skeletonLoader', { assetinfo: response.data.data }, filter);
                    }
                }
            });
    };
    const getCommentList = (loaderType, assetinfo, queryparams = {}) => {
        if (loaderType === 'skeletonLoader') { setSkeletonLoader(true); }
        if (loaderType === 'pageLoader') { setpageLoader(true); }
        globalGetService(`records/comments/${assetinfo?.assetinfo?.id}/?fields=id,record_type,record_uuid,asset_id,redirect_url,comment,created_at&sort_by=created_at&sort=desc`, { ...queryparams, })
            .then((response) => {
                if (loaderType === 'skeletonLoader') { setSkeletonLoader(false) }
                if (loaderType === 'pageLoader') { setpageLoader(false); }
                if (checkApiStatus(response)) {
                    setRecordsComment(response.data.data);
                    setFilter(queryparams)
                }
            });
    };
    const handleStatusMenuItemClick = (e, comment, status) => {
        e.preventDefault();
        globalPutService(`records/comments/${comment.id}/`, { record_type: comment.record_type, record_uuid: comment.record_uuid, status: status })
            .then((response) => {
                if (checkApiStatus(response)) {
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    const updatedComments = recordComment.list.map((c) => {
                        if (c.id === comment.id) {
                            return { ...c, status: status };
                        }
                        return c;
                    });
                    const updatedPagination = { ...recordComment.pagination };
                    setRecordsComment({ list: updatedComments, pagination: updatedPagination });

                    handleStatusMenuClose(comment.id);
                } else {
                    enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                }
            });
    };

    const onChangePage = (event, newPage) => {
        getCommentList('pageLoader', { assetinfo: assetinfo.assetinfo, }, { page: newPage + 1, per_page: recordComment.pagination.per_page, ...filter });
    };

    const onChangeRowsPerPage = (event) => {
        getCommentList('pageLoader', { assetinfo: assetinfo.assetinfo }, { page: 1, per_page: event.target.value });
    };

    const toggleExpandedComment = (commentId) => {
        setExpandedComment((prevExpandedComment) => ({
          ...prevExpandedComment,
          [commentId]: !prevExpandedComment[commentId],
        }));
      };
    return (
        <section>
            <Fragment>
                {skeletonLoader ?
                    (<STableLoader count={5} />) :
                    <Paper square>
                        <Fragment>
                            <FilterComponent
                                filter={filter}
                                filterMenu={commentDetaiFilterOps}
                                getResponseBack={(applyFilter) => getCommentList('pageLoader', { assetinfo: assetinfo.assetinfo }, applyFilter)}
                            />
                            <TableListComp
                                heads={commentDetailHd}
                                data={recordComment?.list?.length > 0 && recordComment?.list?.map((item, index) =>
                                    <CommentsList
                                        item={item}
                                        index={index}
                                        expandedComment={expandedComment}
                                        handleStatusMenuClick={handleStatusMenuClick}
                                        handleStatusMenuItemClick={handleStatusMenuItemClick}
                                        statusAnchorEl={statusAnchorEl}
                                        handleStatusMenuClose={handleStatusMenuClose}
                                        toggleExpandedComment={toggleExpandedComment}
                                    />
                                )}
                                pagination={recordComment?.pagination}
                                onChangePage={onChangePage}
                                onChangeRowsPerPage={onChangeRowsPerPage}
                                noRecord={
                                    recordComment?.list?.length ? null : (
                                        <EmptyCollection title="No Records Found" />
                                    )
                                }
                            />
                        </Fragment>
                    </Paper>
                }
            </Fragment>
            {pageLoader ? <PageLoader /> : null}
        </section>

    );
}
export default CommentDetail