import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { MentionsInput, Mention } from 'react-mentions';
import { Button, Tooltip, Menu, MenuItem, Chip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ForumIcon from '@material-ui/icons/Forum';
import { globalPutService } from '../../../utils/globalApiServices';
import { getLocalStorageInfo, checkApiStatus, checkPermission } from '../../../utils';
import { displayDateFormatShort } from '../../../constants';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import moment from 'moment';

const Comment = ({ fileInfo, item, users, getResponseBack, match, getSubComments, isSubCommentsOpen, onDelete,subComments }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isEdit, setEdit] = useState(false);
    const [editedComment, setEditedComment] = useState('');
    const userInfo = getLocalStorageInfo();

    const showSubComments = () => {
        getSubComments()
    };
    const onEditComment = ({ }) => {
        globalPutService(`records/comments/${item.id}/`,
            { record_type: 2, record_uuid: match.params.uuid, comment: editedComment.comment, redirect_url: match.url, asset_id: fileInfo.id }).then((response) => {
                if (checkApiStatus(response)) {
                    setEdit(false);
                    getResponseBack();
                } else {
                    enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                }
            });
    }
    const initialStatusAnchorEl = {};
    const [statusAnchorEl, setStatusAnchorEl] = useState(initialStatusAnchorEl);
    const newItem = [item]
    newItem.forEach((comment) => {
        initialStatusAnchorEl[comment.id] = null;
    });
    const handleStatusMenuClick = (event, commentId) => {
        setStatusAnchorEl((prevStatusAnchorEl) => ({
            ...prevStatusAnchorEl,
            [commentId]: event.currentTarget,
        }));
    };

    const handleStatusMenuClose = (commentId) => {
        setStatusAnchorEl((prevStatusAnchorEl) => ({
            ...prevStatusAnchorEl,
            [commentId]: null,
        }));
    };
    const handleStatusMenuItemClick = (e, comment, status) => {
        e.preventDefault();
        globalPutService(`records/comments/${comment.id}/`, { record_type: comment.record_type, record_uuid: comment.record_uuid, status: status }).then((response) => {
            if (checkApiStatus(response)) {
                enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                const newItem = [item]
                const updatedComments = [...newItem];
                const commentIndex = updatedComments.findIndex((c) => c.id === comment.id);
                if (commentIndex !== -1) {
                    updatedComments[commentIndex].status = status;
                }
                getResponseBack({ list: updatedComments });
                handleStatusMenuClose(comment.id);
            } else {
                enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
            }
        });
    };
    return (
        <Fragment>
            <div>
                {isEdit ? <MentionsInput
                    value={editedComment.comment}
                    onChange={(e) => setEditedComment({ ...editedComment, comment: e.target.value })}
                    placeholder="Type @ to mention someone"
                    disabled={!isEdit}
                    className={isEdit ? 'comment-input' : 'comment-input-disabled'}
                    maxLength={500}
                >
                    <Mention
                        trigger="@"
                        data={users?.map(item => { return { id: item.email, display: item.name } })}
                        className="comment-mention"
                    />
                </MentionsInput> :
                    <MentionsInput
                        value={item.comment}
                        onChange={(e) => setEditedComment({ ...editedComment, comment: e.target.value })}
                        placeholder="Type @ to mention someone"
                        disabled={!isEdit}
                        className={isEdit ? 'comment-input' : 'comment-input-disabled'}
                        maxLength={500}
                    >
                        <Mention
                            trigger="@"
                            data={users?.map(item => { return { id: item.email, display: item.name } })}
                            className="comment-mention"
                        />
                    </MentionsInput>
                }
                {isEdit ?
                    <Fragment>
                        <Button disabled={item.comment.trim().length === 0} onClick={onEditComment} style={{ marginRight: '5px' }} color="primary" variant='contained' size='small'>Update</Button>
                        <Button onClick={() => { setEdit(false) }} color="primary" variant='outlined' size='small'>Cancel</Button>
                    </Fragment> : null
                }
            </div>
            {!isEdit ? <>
                <ul className='list-inline comment-user-info'>
                    <li className='list-inline-item'>
                        <span>By: {userInfo?.user?.id === item?.created_by.id ? 'Me' : item?.created_by.name}</span>
                    </li>
                    {isSubCommentsOpen ? null : <li style={{ cursor: 'pointer' }} className='list-inline-item'>
                        <Tooltip title='Reply' placement='top'>
                            <ForumIcon color="primary" fontSize="small" onClick={() => showSubComments()} />
                        </Tooltip>
                        <span style={{color:'#3f51b5',verticalAlign:'text-top',marginLeft:'5px'}}>({item?.sub_comments?.length})</span>
                    </li>}
                    {userInfo?.user?.id === item?.created_by.id ?
                        <Fragment>
                            {checkPermission('records', 'comments_page', 'U') ? item.status === 0 && <li style={{ borderLeft: '1px solid #d7d7d7', cursor: 'pointer', paddingLeft: '5px' }} className='list-inline-item'>
                                <EditIcon onClick={() => { setEditedComment(item); setEdit(true) }} color="primary" fontSize='small' />
                            </li> : null}
                            {checkPermission('records', 'comments_page', 'D') ? isSubCommentsOpen === true && item.status === 1 ? null : item.status === 0 && <li style={{ cursor: 'pointer' }} className='list-inline-item'>
                                <DeleteOutlineIcon
                                    onClick={onDelete}
                                    color="secondary" fontSize='small'
                                />
                            </li> : null}

                        </Fragment> : null
                    }
                    <li style={{ cursor: 'pointer', marginLeft: '12px' }} className='list-inline-item'>
                        <span>{moment(item.created_at).format('lll')}</span>
                    </li>
                </ul>
                <ul className='list-inline' style={{ float: "right", margin: '-5px' }}>
                    {isSubCommentsOpen ? null : <li className='list-inline-item '>
                        <Chip
                            style={{ backgroundColor: item.status === 1 && 'green', height: "24px", width: "90px", color: 'white' }}
                            label={item.status === 0 ? 'Open' : 'Resolved'}
                            color={item.status === 0 ? 'primary' : 'default'}
                            onClick={(event) => handleStatusMenuClick(event, item.id)}
                            icon={checkPermission('records', 'comments_page', 'U') ? <ArrowDropDownIcon fontSize="small" style={{ paddingLeft: item.status === 1 ? "59px" : "41px", position: 'absolute', color: 'white' }} /> : null}
                        />
                        {checkPermission('records', 'comments_page', 'U') ? <Menu
                            anchorEl={statusAnchorEl[item.id]}
                            keepMounted
                            open={Boolean(statusAnchorEl[item.id])}
                            onClose={() => handleStatusMenuClose(item.id)}
                        >
                            {item.status === 1 && <MenuItem onClick={(e) => handleStatusMenuItemClick(e, item, 0)}>Open</MenuItem>}
                            {item.status === 0 && <MenuItem onClick={(e) => handleStatusMenuItemClick(e, item, 1)}>Resolved</MenuItem>}
                        </Menu> : null}
                    </li>}
                </ul>
            </>
                : null
            }

        </Fragment>

    )
}
export default withRouter(Comment);