import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Tooltip, MenuItem, Menu } from '@material-ui/core';
import folderIcon from '../../../assets/images/folder_icon.svg';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { getFileIcon, localTimeFn, checkPermission } from '../../../utils';
import { trackActivity } from '../../../utils/mixpanel';
const category = {
  1: 'technical',
  2: 'discrepancy',
  3: 'contract',
  4: 'inspection',
  5: 'maintanence',
  6: 'minipack'
};
const assetDetail = {
  engine: 'ESN',
  aircraft: 'MSN',
  apu: 'APU',
  lg: 'Landing Gear',
  propeller: 'Propeller',
  fan: 'FAN',
  hpc: 'HPC',
  hpt: 'HPT',
  lpt: 'LPT'

};
const GlobalSearchList = ({ history, item, fileIcons, onRenameData, onDeleteData, searchTerm }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="search-list flex-centered">
      <img className="content-icon" src={item.type === 'folder' ? folderIcon : getFileIcon(fileIcons, item.extension)} alt="" />
      <ul className="list-inline search-action-list">
        {checkPermission('records','data_room', 'DN') ?
          <li className="list-inline-item">
            <a href={decodeURIComponent(item.path)} download className="download-icon" onClick={() => trackActivity('File/Folder Downloaded', { page_title: 'Global Search Results', item_type: item.type, item_location: item.location, asset_slug: item.asset_slug, asset_name: item.name, item_size: item.size })}>
              <CloudDownloadIcon fontSize="small" color="primary" />
            </a>
          </li> : null
        }
        {checkPermission('records','data_room', 'D') ?
          <li className="list-inline-item">
            <Tooltip title="Delete" arrow>
              <DeleteIcon onClick={onDeleteData} fontSize="small" color="secondary" />
            </Tooltip>
          </li> : null
        }
        { checkPermission('records','data_room','PUB_REJ') || checkPermission('records','data_room','RENAME') ?
          <li className="list-inline-item">
            <Tooltip title="More Operation" arrow>
              <span
                id="basic-button"
                aria-controls="basic-menu"
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <MoreHorizIcon fontSize="small" color="primary" />
              </span>
            </Tooltip>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              { checkPermission('records','data_room','RENAME') ? <MenuItem onClick={() => { handleClose(); onRenameData() }} >Rename</MenuItem>:null}
              {
              // item.type === 'file' && checkPermission('records','data_room','PUB_REJ') ?
              //   <Fragment>
              //     {item.status !== 'Published' ?
              //       <MenuItem onClick={handleClose}>Publish</MenuItem> : null
              //     }
              //     <MenuItem onClick={handleClose}>Reject</MenuItem>
              //   </Fragment> : null
              }
            </Menu>
          </li>:null
        }
      </ul>
      <div className="search-content">
        <h4 onClick={(e) => item.type === 'file' ? window.open(`/records/preview/${item.uuid}?searchTerm=${searchTerm}`, '_blank') : history.push(item.category <= 5 ? `/${item.asset_type}/${item.asset_slug}/data-room/${category[item.category]}/${item.uuid}` : `/${item.asset_type}/${item.asset_slug}/data-room/technical/mini-pack/detail/${item.uuid}`)}>{item.name}</h4>
        <p>{item.location}</p>
        <ul className="list-inline">
          <li className="list-inline-item"><span>Asset:</span> {assetDetail[item.asset_type]} {item.msn} {item.esn} {item.serial_number}</li>
          <li className="list-inline-item"><span>Author:</span> {item.created_by.first_name} {item.created_by.last_name}</li>
          <li className="list-inline-item"><span>Created Date:</span> {localTimeFn(item.created)}</li>
          <li className="list-inline-item"><span>Occurence Count:</span> {item.occurrence_count}</li>
        </ul>
      </div>
    </div>
  )
}
export default withRouter(GlobalSearchList);
