import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import { STableLoader } from '../../../shared_elements/loaders';
import { TableListComp, PageLoader, EmptyCollection, FilterUIComp, DeletePopUp, ChartDateFilter, NoAccess } from '../../../shared_elements';
import { ActionDockFile, ActionDockTab, DockBulkOps, RenameFileModal, OCRCountCard, OCRStatsChart } from '../components';
import { getPlatformConstantsAc, getAllAssetListAc } from '../../../shared_elements/actionCreators';
import { convertFilterObject, removeEmptyKey, getLocalStorageInfo, checkPermission } from '../../../utils';
import { getActionDocksListApi, getActionDockCountApi, exportActionDocksListApi, publishActionDockApi, rejectActionDockApi, restoreActionDockApi, trashActionDockApi, fileRenamingApi, actionDockAnalyticApi, getUsersFilterApi } from '../apiServices';
import { permissionKeys, actionDockFilterOps, getTableHD, toggleBulkOpsFn, applyFilterFn, onChangeBulkOps, docksSingleActionFn } from '../';
import * as actions from '../../../shared_elements/actions';
import { imageMedia, videoTypes, backendDateFormat, imgPath } from '../../../constants';
import { trackActivity } from '../../../utils/mixpanel';
import DeploymentMessage from '../../../shared_elements/components/DeploymentMessage';
class ActionDocks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skeletonLoader: false,
      pageLoader: false,
      selectAllSearch: false,
      ocrAnalyticFlag: false,
      start_date: moment().subtract(7, 'days').format(backendDateFormat),
      end_date: moment().format(backendDateFormat),
      customDateFlag: false,
      sort: '',
      sort_by: '',
      filter: {},
      applyingFilter: {},
      actionDocks: {
        list: [],
        pagination: null
      },
      bulkOperation: { ids: [], modal: false, title: '' },
      fileRenameInfo: {
        modal: false,
        data: null
      },
      actionDockAnalytic: {},
      actionDockCount: null,
      usersFilter:[]
    }
    // Bind Api
    this.getActionDocksListApi = getActionDocksListApi.bind(this);
    this.getActionDockCountApi = getActionDockCountApi.bind(this);
    this.exportActionDocksListApi = exportActionDocksListApi.bind(this);
    this.restoreActionDockApi = restoreActionDockApi.bind(this);
    this.publishActionDockApi = publishActionDockApi.bind(this);
    this.rejectActionDockApi = rejectActionDockApi.bind(this);
    this.trashActionDockApi = trashActionDockApi.bind(this);
    this.fileRenamingApi = fileRenamingApi.bind(this);
    this.actionDockAnalyticApi = actionDockAnalyticApi.bind(this);
    this.getUsersFilterApi = getUsersFilterApi.bind(this);
    // Bind Function
    this.toggleBulkOpsFn = toggleBulkOpsFn.bind(this);
    this.applyFilterFn = applyFilterFn.bind(this);
    this.onChangeBulkOps = onChangeBulkOps.bind(this);
    this.docksSingleActionFn = docksSingleActionFn.bind(this);
    this.getTableHD = getTableHD.bind(this);
  }
  componentDidMount() {
    this.getUsersFilterApi ({tab: this.props.match.params.type})
    if (checkPermission('reviewdock',permissionKeys[this.props.match.params.type],'R')) {
      this.getActionDocksListApi(this.props, { tab: this.props.match.params.type }, 'skeletonLoader');
      this.getActionDockCountApi(this.props);
      if (!this.props.pltConstants.filter(item => item.type === 'file_extension_drive').length) {
        this.props.getPlatformConstants();
      }
      this.props.getAllAssetListFn()
      trackActivity('Page Visited', {
        page_title: 'Review Dock'
      })
    } else {
      // this.props.enqueueSnackbar('Access is restricted', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
    }
    
  }
  removeFilter = (keyParam) => {
    const { filter, applyingFilter, actionDocks } = this.state;
    let query = Object.assign({}, filter);
    delete query[keyParam];
    this.getActionDocksListApi(this.props, { ...query, tab: this.props.match.params.type, per_page: actionDocks.pagination.per_page }, 'pageLoader');
    this.setState({ applyingFilter: delete applyingFilter[keyParam] })
  }
  getFileRename = (item) => {
    this.setState(prevState => ({
      ...prevState,
      fileRenameInfo: {
        ...prevState.fileRenameInfo,
        modal: true,
        data: item
      }
    }))
  }
  onChangeFileName = (name) => {
    this.setState(prevState => ({
      ...prevState,
      fileRenameInfo: {
        ...prevState.fileRenameInfo,
        data: {
          ...prevState.fileRenameInfo.data,
          name: name
        }
      }
    }))
  }
  onChangeOCRAnalytic = () => {
    if(this.state.ocrAnalyticFlag == false ){
      this.actionDockAnalyticApi(this.props, { start_date: this.state.start_date, end_date: this.state.end_date, action_dock: true });
    }
    this.setState(prevState => ({
      ...prevState,
      ocrAnalyticFlag: !prevState.ocrAnalyticFlag
    }));
  }
  changeStatusFn = () => {
    const { bulkOperation, selectAllSearch, filter } = this.state;
    const { allAssets } = this.props;
    let option = allAssets.find(item => item.id === filter.asset);
    let data = {
      tab: this.props.match.params.type,
      uuid: bulkOperation.ids,
      type: 'file',
    }
    if (bulkOperation.multi && selectAllSearch) {
      if (filter.name) {
        data = {
          ...data,
          name: filter.name
        }
      }
      if (filter.asset) {
        data = {
          ...data,
          asset_type: option.asset_type,
          asset_id: option.id,
        }
      }
    }
    if (selectAllSearch) {
      delete data.objects
    }
    if (bulkOperation.title === 'Publish') {
      this.publishActionDockApi(this.props, data)
    } else if (bulkOperation.title === 'Restore') {
      this.restoreActionDockApi(this.props, data)
    } else if (bulkOperation.title === 'Reject') {
      this.rejectActionDockApi(this.props, data)
    } else if (bulkOperation.title === 'Trash') {
      this.trashActionDockApi(this.props, data)
    }
  }
  render() {
    const { skeletonLoader, pageLoader, selectAllSearch, ocrAnalyticFlag, actionDockAnalytic, start_date, end_date, customDateFlag, fileRenameInfo, actionDockCount, bulkOperation, actionDocks, filter, applyingFilter, sort, sort_by, usersFilter } = this.state;
    const { pltConstants, match, allAssets } = this.props;
    let filterOptions = actionDockFilterOps;
    filterOptions = {
      ...filterOptions,
      asset: {
        ...filterOptions.asset,
        options: allAssets.sort((a, b) => a.unique_name !== b.unique_name ? a.unique_name < b.unique_name ? -1 : 1 : 0).map((item) => { return { ...item, id: `${item.id}_${item.asset_type}` } })
      },
      user_id:{
        ...filterOptions.user_id,
        options:usersFilter
      }
    }
    console.log('usersFilter',usersFilter);
    if(checkPermission('reviewdock',permissionKeys[this.props.match.params.type],'R')){
      return (
        <section className="action-docks">
          <DeploymentMessage/>
          <div className="heading flex-centered">
            <h3>Review Dock</h3>
          </div>
          <ActionDockTab
            actionDockCount={actionDockCount}
            getFilesList={(tab) => { this.setState({ bulkOperation: { ids: [], modal: false, title: '' },applyingFilter:{} }); this.getActionDocksListApi(this.props, { tab: tab }, 'pageLoader');this.getUsersFilterApi ({tab: tab}) }}
          />
          <Fragment>
            {skeletonLoader ?
              (<STableLoader count={7} />) :
              <Fragment>
                <DockBulkOps
                  bulkOperation={bulkOperation}
                  filter={filter}
                  ocrAnalyticFlag={ocrAnalyticFlag}
                  actionDocks={actionDocks}
                  onChangeBulkOps={this.onChangeBulkOps}
                  selectAllSearch={selectAllSearch}
                  selectOnSearch={(flag) => this.setState({ selectAllSearch: flag })}
                  exportReportFn={(file) => this.exportActionDocksListApi(this.props, { ...filter, tab: this.props.match.params.type, download: file.extension })}
                  onChangeOCRAnalytic={this.onChangeOCRAnalytic}
                /> 
                {!ocrAnalyticFlag ?
                  <Fragment>
                    <FilterUIComp
                      filter={filter}
                      applyingFilter={applyingFilter}
                      removeFilter={this.removeFilter}
                      removeAllFilter={() => { this.setState({ applyingFilter: {} }); this.getActionDocksListApi(this.props, { tab: this.props.match.params.type }, 'pageLoader') }}
                      submitFilter={() => this.getActionDocksListApi(this.props, { tab: this.props.match.params.type, ...convertFilterObject(removeEmptyKey(applyingFilter), filterOptions), per_page: actionDocks.pagination.per_page, sort: sort, sort_by: sort_by }, 'pageLoader')}
                      applyFilter={this.applyFilterFn}
                      filterMenu={filterOptions}
                    />
                    <TableListComp
                      bulkOperation={checkPermission('reviewdock',permissionKeys[match.params.type],'PUB_REJ') || checkPermission('reviewdock',permissionKeys[match.params.type],'D') || checkPermission('reviewdock',permissionKeys[match.params.type],'RSTOR')}
                      bulkFlag={actionDocks.list.length === bulkOperation.ids.length && actionDocks.list.length > 0}
                      toggleBulkOps={(flag) => this.toggleBulkOpsFn(flag, 'all')}
                      heads={this.getTableHD(this.props)}
                      data={actionDocks.list.map((item, index) =>
                        <ActionDockFile
                          item={item}
                          index={index}
                          bulkOperation={bulkOperation}
                          toggleBulkOps={(flag) => this.toggleBulkOpsFn(flag, 'single', item.uuid)}
                          docksSingleActionFn={(title) => this.docksSingleActionFn(title, item.uuid)}
                          fileIcons={pltConstants.filter(icon => icon.type === 'file_extension_drive')}
                          getFileRename={(data) => this.getFileRename({ ...item, ...data })}
                        />
                      )}
                      pagination={actionDocks.pagination}
                      onChangePage={(event, newPage) => { this.setState({ bulkOperation: { ids: [], modal: false, title: '' } }); this.getActionDocksListApi(this.props, { tab: this.props.match.params.type, page: newPage + 1, per_page: actionDocks.pagination.per_page, ...filter }, 'pageLoader') }}
                      onChangeRowsPerPage={(event) => this.getActionDocksListApi(this.props, { tab: this.props.match.params.type, page: 1, per_page: event.target.value, ...filter }, 'pageLoader')}
                      noRecord={actionDocks.list.length ? null :
                        <EmptyCollection
                          title="No Records Found"
                        />
                      }
                    />
                  </Fragment> :
                  <div className="ocr-analytic-blk">
                    {Object.keys(actionDockAnalytic).length ?
                      <Fragment>
                        <Grid container spacing={2}>
                          <OCRCountCard title="Total OCRed Files" count={actionDockAnalytic.general_info.total_ocred_file} />
                          <OCRCountCard title="Total OCR Files In Process" count={actionDockAnalytic.general_info.ocr_files_in_process} />
                          <OCRCountCard title="Total OCR Files InQueue" count={actionDockAnalytic.general_info.ocr_file_in_queue} />
                          <OCRCountCard title="Total OCR Files Rejected" count={actionDockAnalytic.general_info.ocr_files_rejected} />
                        </Grid>
                        <div>
                          <ChartDateFilter
                            start_date={start_date}
                            end_date={end_date}
                            onChangeDays={(days) => { this.actionDockAnalyticApi(this.props, { start_date: moment().subtract(days, 'days').format(backendDateFormat), end_date: moment().format(backendDateFormat), action_dock: true }); this.setState({ start_date: moment().subtract(days, 'days').format(backendDateFormat), end_date: moment().format(backendDateFormat), customDateFlag: false }) }}
                            customDateFlag={customDateFlag}
                            onChangeCustomDate={(startDate, endDate) => { this.actionDockAnalyticApi(this.props, { start_date: startDate, end_date: endDate, action_dock: true }); this.setState({ start_date: startDate, end_date: endDate, customDateFlag: true }) }}
                          />
                          <OCRStatsChart
                            actionDockAnalytic={actionDockAnalytic}
                          />
                        </div>
                      </Fragment> : null
                    }
                  </div>
                }
              </Fragment>
            }
          </Fragment>
          {bulkOperation.modal ?
            <DeletePopUp
              modal={bulkOperation.modal}
              toggleModalFn={() =>
                this.setState(prevState => ({
                  ...prevState,
                  bulkOperation: {
                    ...prevState.bulkOperation,
                    modal: false,
                    ids: prevState.bulkOperation.multi ? prevState.bulkOperation.ids : []
                  }
                }))
              }
              title={bulkOperation.title ? bulkOperation.title : ''}
              content={<h4>{`Are you sure you want to ${bulkOperation.title ? bulkOperation.title : ''}?`}</h4>}
              triggerEventFn={() => this.changeStatusFn()}
              confirmText={bulkOperation.title}
            /> : null
          }
          {fileRenameInfo.modal ?
            <RenameFileModal
              fileRenameInfo={fileRenameInfo}
              onChangeFileName={this.onChangeFileName}
              toggleModalFn={() => this.setState({ fileRenameInfo: { modal: false, data: null } })}
              saveFileName={(data) => this.fileRenamingApi(this.props, fileRenameInfo, data.publish)}
            /> : null
          }
          {pageLoader ? <PageLoader /> : null}
        </section>
      ) 
    }else{
      return <NoAccess/>
    }
  }
}
const mapStateToProps = state => ({
  pltConstants: state.shareReducer.pltConstants,
  allAssets: state.shareReducer.allAssets,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getPlatformConstants: () => dispatch(getPlatformConstantsAc({ constant_types: ['file_extension_drive'] })),
    getAllAssetListFn: () => dispatch(getAllAssetListAc()),
  }
}
export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(ActionDocks))
