import React from 'react';
import { Button } from '@material-ui/core';
import componentPermissionHoc from '../../../hoc/componentPermissionHoc'
import { checkPermission } from '../../../utils';
const EmptyFolder = ({toggleUploadModalFn, showUpload}) => {
  return(
    <div className="empty-folder">
      <div className="upload-now-content">
        {
          !showUpload ?
          <p>No Result found</p>
          :<p>This folder is empty</p>
        }
        { checkPermission('records','data_room','UP') && showUpload ?
            <Button onClick={toggleUploadModalFn} variant="contained" color="primary">Upload Now</Button>
          :null
        }
      </div>
    </div>
  )
}
export default EmptyFolder;
