import * as actions from './actions';
export default function( state = {
  storageUsage:{
    used:0,
    available:0
  },
  allAssets:[],
  searchLoader:false,
  applicationSwitcher:false,
  recordsSearch:{
    pagination:null,
    results:[]
  },
  recordsFilter:{},
  portfolios:[],
  pltConstants: [],
  lessees:[],
  operators:[],
  aircraftTypes: [],
  aircraftMntGrp:[],
  engineTypes:[],
  apuTypes: [],
  regions: [],
  filteredAircraftTypeList:[],
  filteredEngTypeList:[],
  filteredApuTypeList:[],
  recordsRepos:null,
}, action){
  switch (action.type) {
    case actions.PLATFORM_CONTANTS:
      return {
        ...state,
        pltConstants: action.payload
      }
    case actions.RECORDS_REPOS:
      return {
        ...state,
        recordsRepos: action.payload
      }
    case actions.REGION_LIST:
      return {
        ...state,
        regions: action.payload
      }
    case actions.PORTFOLIO_LIST:
      return {
        ...state,
        portfolios: action.payload
      }
    case actions.AIRCRAFT_TYPES:
      return {
        ...state,
        aircraftTypes: action.payload
      }
    case actions.AIRCRAFT_MNT_GRP:
      return {
        ...state,
        aircraftMntGrp: action.payload
      }
    case actions.ENGINE_TYPES:
      return {
        ...state,
        engineTypes: action.payload
      }
    case actions.APU_TYPES:
      return {
        ...state,
        apuTypes: action.payload
      }
    case actions.LESSEE_LIST:
      return {
        ...state,
        lessees: action.payload
      }
    case actions.OPERATOR_LIST:
      return {
        ...state,
        operators: action.payload
      }
    case actions.RECORDS_SEARCH_RESULT:
      if(action.queryParams.page === 1){
        return {
          ...state,
          recordsSearch: action.payload
        }
      }else{
        return {
          ...state,
          recordsSearch: {
            ...state.recordsSearch,
            results:[...state.recordsSearch.results,...action.payload.results],
            pagination:action.payload.pagination
          }
        }
      }
    case actions.RECORDS_RENAMED:
      return {
        ...state,
        recordsSearch: {
          ...state.recordsSearch,
          results: state.recordsSearch.results.map(item => item.type === action.payload.type && item.uuid === action.payload.uuid ? {...item, name:action.payload.new_name} :item)
        }
      }
    case actions.RECORDS_DELETE:
      return {
        ...state,
        recordsSearch: {
          ...state.recordsSearch,
          results: state.recordsSearch.results.filter(item => !(item.type === action.payload.type && item.uuid === action.payload.uuid)),
          pagination: {
            ...state.recordsSearch.pagination,
            total:state.recordsSearch.pagination.total-1
          }
        }
      }
    case actions.RECORDS_SEARCH_FILTER:
      return {
        ...state,
        recordsFilter: action.payload
      }
    case actions.SEARCH_LOADER:
      return {
        ...state,
        searchLoader: action.payload
      }
    case actions.APPS_SWITCHER:
      return {
        ...state,
        applicationSwitcher:action.payload
      }
    case actions.ALL_ASSETS:
      return {
        ...state,
        allAssets: action.payload
      }
    case actions.UPDATE_STORAGE_USAGR:
      return {
        ...state,
        storageUsage: action.payload
      }
    case actions.UNMOUNT_ASSET_LIST:
      return {
        ...state,
        allAssets:[]
      }
        case actions.FILTERED_AIRCRAFT_TYPE_LIST:
        return{
          ...state,
          filteredAircraftTypeList: action.payload
        }
        case actions.FILTERED_ENGINE_TYPE_LIST:
        return{
          ...state,
          filteredEngTypeList: action.payload
        }
        case actions.FILTERED_APU_TYPE_LIST:
        return{
          ...state,
          filteredApuTypeList: action.payload
        }
    default:
      return state;
  }
}
