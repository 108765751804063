import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from '@material-ui/core';
import { checkApiStatus, checkPermission } from '../../../utils';
import { PageLoader, DeletePopUp } from '../../../shared_elements';
import AttachmentIcon from '@material-ui/icons/Attachment';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import { globalDeleteService, globalGetService } from '../../../utils/globalApiServices';
const ChecklistLinkedFiles = ({item, isEdit, getResponseBack}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [checkListData, setCheckListData] = useState({list:[]})
  const [deleteFile, setDeleteFile] = useState({modal:false, data:null})
  const getLinkedFiles = () => {
    setLoading(true);
    globalGetService(`records/checklist-linking/`, {checklist_id:item.id})
    .then(response => {
      if(checkApiStatus(response)){
        setCheckListData(response.data.data);
        setModal(true);
      }
      setLoading(false);
    })
  }
  const onUnlinkFile = () => {
    globalDeleteService(`records/checklist-linking/${deleteFile.data.file_uuid}/checklist/${item.id}/`)
    .then(response => {
        if(checkApiStatus(response)){
            enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
            setCheckListData({...checkListData, list:checkListData.list.filter(item => item.file_uuid !== deleteFile.data.file_uuid)})
            setDeleteFile({modal:false, data:null})
            getResponseBack();
        }else{
            enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
        }
    })
  }
  return(
    <>

        <span style={{cursor:'pointer'}} onClick={getLinkedFiles} className='flex-centered'><AttachmentIcon fontSize='small' color='primary' /> <span>({item.linked_files})</span></span>
        { modal ?
            <Dialog
                open={modal}
                onClose={() => setModal(false)}
                className='project-management-modal'
                maxWidth='md'
            >
                <DialogTitle id="scroll-dialog-title">Linked File(s)</DialogTitle>
                <DialogContent dividers={true}>
                    <div style={{width:'420px'}} className='checklist-file-linking'>
                        {checkListData?.list?.length ?
                            <div>
                                {checkListData.list.map((fileItem, index) => 
                                    <Grid container spacing={1}>
                                        <Grid item md={9}>{fileItem.file_name}</Grid>
                                        <Grid item md={3}>
                                            <ul className='list-inline' style={{float:'right'}}>
                                                <li className='list-inline-item' onClick={() => window.open(fileItem.url, 'download')}>
                                                    <Tooltip title="Download" arrow><VerticalAlignBottomIcon fontSize='small' color='primary' /></Tooltip>
                                                </li>
                                                { checkPermission('records','inspection_checklist','U') && isEdit ?
                                                    <li className='list-inline-item' onClick={() => setDeleteFile({modal:true, data:fileItem})}>
                                                        <Tooltip title="Unlink" arrow><DeleteOutlineIcon fontSize='small' color='secondary' /></Tooltip>
                                                    </li>:null
                                                }
                                            </ul>
                                        </Grid>
                                    </Grid>
                                )}
                            </div>:null
                        }
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setModal(false)} color="primary" size='small'>Close</Button>
                </DialogActions>
            </Dialog>:null
        }
        { deleteFile.modal ?
                <DeletePopUp
                    modal={deleteFile.modal}
                    toggleModalFn={() => setDeleteFile({modal:false, data:null})}
                    title="Unlink File"
                    content={<h4>Are you sure you want to unlink?</h4>}
                    triggerEventFn={onUnlinkFile}
                    confirmText={'unlink'}
                />:null
            }
        { isLoading ? <PageLoader/>:null }
    </>
  )
}
export default withRouter(ChecklistLinkedFiles);
