import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import {getGlobalCookie, getLocalStorageInfo, authorizeAutoLogin, getLessorsList, setGlobalCookie } from '../utils'
import { lessorUpdateApi } from '../application/auth/apiServices';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import config from '../config';
window.addEventListener('visibilitychange', checkLogin)
function checkLogin() {
  if(document.visibilityState || document.hasFocus){
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let location = window.location.href
    let lessorAccess =JSON.parse(getGlobalCookie('lessorAccess'))
    if(!window.location.href.includes('login') && !window.location.href.includes('select-lessor')){
      setGlobalCookie('redirectURIRecords', window.location.href, 3)
    }
    setTimeout(() => {
      if(lessorAccess === undefined || lessorAccess === null){
          localStorage.clear();
          if(!location.includes('login')){
           // window.location.reload()
          }
        }else{
          if(lessorAccess.id && config.api.networkInterface.includes(lessorAccess.environment) ){
            if(!getLocalStorageInfo()){
               authorizeAutoLogin({lessor_id:lessorAccess.id})
             }else if(!location.includes('select-lessor') && !location.includes('otp')){
               if(lessorAccess.id !== userInfo.defaultLessor.id || getLocalStorageInfo().access !== lessorAccess.access){
                 authorizeAutoLogin({lessor_id:lessorAccess.id})
               }else{
                 // do nothing
               }
             }
          }else {
            if(lessorAccess.access && userInfo && !userInfo.lessor_list){
              // getLessorsList()
            }
          }
        }
    }, 2000)
  }
}
export default function UserLogged(HocComponent){
  return class extends Component{
    constructor(props){
      super(props)
      this.state = {
        modal: false
      }
      this.lessorUpdateApi = lessorUpdateApi.bind(this)
    }
    componentDidMount(){
      checkLogin()
    }
    render(){
      const lessorAccess =  JSON.parse(getGlobalCookie('lessorAccess'))
      if(lessorAccess && lessorAccess.access && lessorAccess.id && config.api.networkInterface.includes(lessorAccess.environment) ){
        return(
          <Redirect to="/assets" />
        )
      }else {
        return(
          <>
          <Dialog
            open={this.state.modal}
            onClose={() => this.setState({modal:false})}
            aria-labelledby="scroll-dialog-title"
            fullWidth= 'true'
          >
            <DialogTitle id="scroll-dialog-title">
              Information<CloseIcon onClick={() => this.setState({modal:false})} color="primary" style={{float: 'right', cursor: 'pointer'}}/>
            </DialogTitle>
            <DialogContent dividers={true} className="data-upload-modal">
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    Dear User,
    <br/><br/>
SPARTA will be under routine maintenance and feature upgrade release from Saturday 12 Feb 2022 0030 Hrs UTC to Sunday 13 Feb 2022 2300 Hrs UTC. During this time, SPARTA will be accessible but may pose intermittent connection problems. We regret the inconvenience caused.
<br/><br/>
If you have any concerns or questions, please contact us on <a href="mailto:support@sparta.aero">support@sparta.aero</a>.
<br/><br/>
Regards,
<br/>
The SPARTA Team
                  </Grid>
                </Grid>
              </form>
            </DialogContent>
          </Dialog>
          <HocComponent {...this.props} />
          </>
        )
      }
    }
  }
}
