import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, CircularProgress } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { CkEditorText, AutocompleteCreatable } from '../../../shared_elements'
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../constants';
import { globalGetService, globalPostService } from '../../../utils/globalApiServices';
import { checkApiStatus } from '../../../utils';
import { regexConstants } from '../../../constants/regEx';
const AddIssue = ({match, addEditIssue, toggleModalFn, getResponseBack, uuid='', recordType='', assetType=null,assetSlug=null}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [users, setUsers] = useState([]);
    const [issue, setIssue] = useState(addEditIssue.data);
    const [error, setError] = useState({});
    useEffect(() => {
        getCategories();
        getAssignees();
    },[])
    const onFieldChange = (key, value) => {
        setIssue({...issue, [key]:value})
    }
    const getCategories = () => {
        globalGetService(`records/asset/${assetType?assetType:match.params.assetType}/${assetSlug?assetSlug:match.params.assetSlug}/gap-report-sections/`)
        .then(response => {
            if(checkApiStatus(response)){
                setCategories(response.data.data.list)
            }
        })
    }
    const getAssignees = () => {
        globalGetService(`records/userslist/`,{application:6})
        .then(response => {
            if(checkApiStatus(response)){
                setUsers(response.data.data);
            }
        })
    }
    const onAddIssue = () => {
        let validationInputs = { 
            title:issue?.title?.trim()?.length ? '':'Please enter Title',
            description:issue?.description?.trim()?.length ? '':'Please enter Description',
            status:issue?.status?.label ? '':'Please select Status'
        }
        if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
            setLoading(true);
            let payload = Object.assign({}, issue);
            if(uuid){
                payload = {
                    ...payload,
                    record_uuid:uuid,
                    record_type:recordType === 'file' ? 1:2
                }
            }
            globalPostService(`records/asset/${assetType?assetType:match.params.assetType}/${assetSlug?assetSlug:match.params.assetSlug}/issues/`, payload)
            .then(response => {
                if(checkApiStatus(response)){
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    toggleModalFn();getResponseBack();
                }else{
                    enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                }
                setLoading(false);
            })
        }else{
            setError(validationInputs);
        }
    }
    return(
        <Dialog
            open={addEditIssue.modal}
            onClose={toggleModalFn}
            fullWidth= 'true'
            className='records-dataroom-modal'
            maxWidth='md'
            disableEnforceFocus
        >
            <DialogTitle id="scroll-dialog-title">
                Add Issue
            </DialogTitle>
            <DialogContent dividers={true}>
                <Grid container spacing={1}>
                    <Grid item md={12}>
                        <TextField 
                            required
                            id='title'
                            label='Issue Title'
                            value={issue.title ? issue.title : ''}
                            fullWidth
                            margin="normal"
                            error={error.title}
                            helperText={error?.title||''}
                            onChange={(e) => onFieldChange('title', e.target.value)}
                            InputLabelProps={{shrink: true}}
                            variant='outlined'
                            onFocus={() => setError({...error,title:''})}
                            inputProps={{ maxLength: 100 }}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <label className='for-label mui-label-type'>Description<sup>*</sup></label>
                        <CkEditorText
                            disabled={false}
                            htmlData={issue?.description||''}
                            onChangeData={(data) => {onFieldChange('description', data); setError({...error,'description':''})}}
                        />
                        {error?.description?.trim()?.length ? <p className='error-msg'>{error.description}</p>:null}
                    </Grid>
                    <Grid item md={4}>
                        <AutocompleteCreatable
                            placeholder=""
                            options={categories}
                            value={issue?.category||null}
                            paramsKey='category'
                            optionKey="name"
                            required={false}
                            multiple={false}
                            label="Category"
                            onFieldChange={(paramsKey, value) => onFieldChange('category', value)}
                        />
                        <div style={{display:'none'}}>
                            <Autocomplete
                                options = {categories}
                                getOptionLabel={option => option.name}
                                id="category"
                                value={issue.category? issue.category : null}
                                onChange={(e, value) => onFieldChange('category', value)}
                                renderInput={params => <TextField {...params} label="Category" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                            />
                        </div>
                        
                    </Grid>
                    <Grid item md={4}>
                        <Autocomplete
                            options = {[{label:'Open',value:'0'},{label:'Closed',value:'1'}, {label:'Reopen',value:'2'},{label:'Unresolved',value:'3'}]}
                            getOptionLabel={option => option.label}
                            id="status"
                            value={issue.status? issue.status : null}
                            onChange={(e, value) => onFieldChange('status', value)}
                            renderInput={params => <TextField error={error.status} helperText={error?.status || ''} required {...params} label="Status" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' onFocus={() => setError({...error,status:''})} />}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <Autocomplete
                            options = {[{label:'None',value:0},{label:'P1',value:1},{label:'P2',value:2}]}
                            getOptionLabel={option => option.label}
                            id="priority"
                            value={issue.priority? issue.priority : 0}
                            onChange={(e, value) => onFieldChange('priority', value)}
                            renderInput={params => <TextField {...params} label="Priority" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <Autocomplete
                            options = {users}
                            getOptionLabel={option => option.name}
                            id="assignee"
                            value={issue.assignee? issue.assignee : null}
                            onChange={(e, value) => onFieldChange('assignee', value)}
                            renderInput={params => <TextField {...params} label="Assignee" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                id="started_date"
                                label="Discrepancy Raised on"
                                format={fieldDateFormat}
                                fullWidth
                                InputLabelProps={{shrink: true}}
                                value={issue.started_date?issue.started_date:null}
                                onChange={(data, value) => onFieldChange('started_date', data ? moment(data).format(backendDateFormat):data)}
                                inputVariant='outlined'
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={4}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                id="estimated_completion_date"
                                label="Discrepancy Resolved on"
                                format={fieldDateFormat}
                                fullWidth
                                InputLabelProps={{shrink: true}}
                                value={issue.estimated_completion_date?issue.estimated_completion_date:null}
                                onChange={(data, value) => {onFieldChange('estimated_completion_date', data ? moment(data).format(backendDateFormat):data); setError({...error,status:''})}}
                                inputVariant='outlined'
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={4}>
                        <TextField 
                            id='ata'
                            label='ATA'
                            value={issue.ata ? issue.ata : ''}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{shrink: true}}
                            inputProps={{ maxLength: 25 }}
                            onChange={(e) => onFieldChange('ata', e.target.value)}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField 
                            id='cycles_remaining'
                            label='Cycles Remaining'
                            value={issue.cycles_remaining ? issue.cycles_remaining : ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => regexConstants.onlyNumeric.test(e.target.value) ?onFieldChange('cycles_remaining', e.target.value):e.preventDefault()}
                            InputLabelProps={{shrink: true}}
                            inputProps={{ maxLength: 25 }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField 
                            id='part_number'
                            label='Part Number'
                            value={issue.part_number? issue.part_number : ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('part_number', e.target.value)}
                            InputLabelProps={{shrink: true}}
                            inputProps={{ maxLength: 25 }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField 
                            id='serial_number'
                            label='Serial Number'
                            value={issue.serial_number? issue.serial_number : ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('serial_number', e.target.value)}
                            InputLabelProps={{shrink: true}}
                            inputProps={{ maxLength: 25 }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                id="estimated_completion_date"
                                label="Installed On"
                                format={fieldDateFormat}
                                fullWidth
                                InputLabelProps={{shrink: true}}
                                value={issue.install_date?issue.install_date:null}
                                onChange={(data, value) => onFieldChange('install_date', data ? moment(data).format(backendDateFormat):data)}
                                inputVariant='outlined'
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={4}>
                        <TextField 
                            id='position'
                            label='Position'
                            value={issue.position ?issue.position:''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('position', e.target.value)}
                            InputLabelProps={{shrink: true}}
                            inputProps={{ maxLength: 50 }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={12}>
                        <label className='for-label mui-label-type'>Discrepancy Requirement</label>
                        <CkEditorText
                            disabled={false}
                            htmlData={issue?.requirement_remarks||''}
                            onChangeData={(data) => onFieldChange('requirement_remarks', data)}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <label className='for-label mui-label-type'>Discrepancy Background</label>
                        <CkEditorText
                            disabled={false}
                            htmlData={issue?.background_remarks||''}
                            onChangeData={(data) => onFieldChange('background_remarks', data)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color="primary" variant="text">CANCEL</Button>
                <Button disabled={isLoading} onClick={onAddIssue} color="primary" variant="contained">
                    {isLoading ? <CircularProgress size={24} />:'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    )

}
export default withRouter(AddIssue)